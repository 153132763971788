import axios from "axios";

///////////// || Get Request || /////////////
export const getData = async (path) => {
    try {
        const res = await axios.get(path);
        return res.data;
    } catch (err) {
        throw err;
    }
};

///////////// || Post Request || /////////////
export const postData = async (path, data) => {
    try {
        const res = await axios.post(path, data);
        return res.data;
    } catch (err) {
        throw err;
    }
};

///////////// || Put Request || /////////////
export const putData = async (path, data) => {
    try {
        const res = await axios.put(path, data);
        return res.data;
    } catch (err) {
        throw err;
    }
};

///////////// || Patch Request || /////////////
export const patchData = async (path, data) => {
    try {
        const res = await axios.patch(path, data);
        return res.data;
    } catch (err) {
        throw err;
    }
};

///////////// || Delete Request || /////////////
export const deleteData = async (path) => {
    try {
        const res = await axios.delete(path);
        return res.data;
    } catch (err) {
        throw err;
    }
};