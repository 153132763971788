import { useEffect, useState } from "react";
import {
    Box,
} from "@mui/material";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";

export const TabelLine = (props) => {
    const [data, setData] = useState({
        data: props.data.order_lines,
        loading: false,
        dialog: false,
        filters: {
            skip: 0,
            take: 25,
            page: 0,
            search: ""
        }
    });

    const fetchData = () => {
        setData((old) => ({
            ...old,
            data: props.data.order_lines,
            loading: false,
            dialog: false,
            filters: {
                skip: 0,
                take: 25,
                page: 0,
                search: ""
            }
        }))
    };

    let filters = data.filters.page ||
        data.filters.take ||
        data.filters.skip;

    useEffect(() => {
        fetchData()
    }, [filters])


    const columns = [
        {
            field: "name",
            headerName: "Name",
            headerClassName: "grid--header",
            minWidth: 270,
            renderCell: (params) => {
                return (
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                        <ColumnText text={params.row?.product_name} variant="subtitle1" fontWeight="bold" />
                    </Box>
                );
            },
        },
        {
            field: "unit_price",
            headerName: "Price",
            headerClassName: "grid--header",
            minWidth: 270,
            renderCell: (params) => {
                return (
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                        <ColumnText text={params.row?.unit_price} variant="subtitle1" fontWeight="bold" />
                    </Box>
                );
            },
        },
        {
            field: "qty",
            headerName: "QTY",
            headerClassName: "grid--header",
            minWidth: 270,
            renderCell: (params) => {
                return (
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                        <ColumnText text={params.row?.order_line_keys?.length > 0 ? params.row?.order_line_keys?.length : 1} variant="subtitle1" fontWeight="bold" />
                    </Box>
                );
            },
        },
    ];


    return (
        <>
            <Box m={2}>
                <Box mt={2}>
                    <DataGridTabel columns={columns} data={data} setData={setData} footerShow={true} height={'30vh'} />
                </Box>
            </Box>
        </>
    );
};

export default TabelLine;
