import { deleteData, getData, postData, putData,patchData } from "..";

// get Slider
export const GetSliderData = async (skip, take, search) => {
    if (skip !== undefined && take !== undefined || search !== search) {
        try {
            const request = await getData(`/slider?page=${skip === 0 ? 1 : skip || take}&page_size=${take}&search=${search}`); // Handle the data
            return request;
        } catch (error) {
            console.error(error); // Handle the error
            throw error;
        }
    }
};

// get Slider By Id
export const GetSliderByIdData = async (id) => {
    if (id) {
        try {
            const request = await getData(`/admin/Slider/${id}`); // Handle the data
            return request;
        } catch (error) {
            console.error(error); // Handle the error
            throw error;
        }
    }
};

// post Slider
export const PostSlider = async (data) => {
    try {
        const request = await postData(`/slider/`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

// patch Slider
export const PatchSlider = async (id, data) => {
    try {
        const request = await patchData(`/slider/${id}/`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

// put Slider
export const PutSlider = async (id, data) => {
    try {
        const request = await putData(`/slider/${id}/`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

// delete Slider
export const DeleteSlider = async (id) => {
    try {
        const request = await deleteData(`/slider/${id}/`); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

