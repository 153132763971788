import { deleteData, getData, patchData, postData, putData } from "..";

// get SubCategory
export const GetSubCategoryData = async (
  skip,
  take,
  search,
  selectedCategory,
  createdAfter,
  createdBefor
) => {
  if ((skip !== undefined && take !== undefined) || search !== search) {
    try {
      const request = await getData(
        `/sub_category?page=${
          skip === 0 ? 1 : skip || take
        }&page_size=${take}&search=${search}${
          selectedCategory ? `&category__id=${selectedCategory}` : ""
        }${createdAfter ? `&created_after=${createdAfter}` : ""}${
          createdBefor ? `&created_before=${createdBefor}` : ""
        }`
      ); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// get SubCategory By Id
export const GetSubCategoryByIdData = async (id) => {
  if (id) {
    try {
      const request = await getData(`/sub_category/${id}`); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// post SubCategory
export const PostSubCategory = async (data) => {
  try {
    const request = await postData(`/sub_category/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// post sub category with slider data

export const PostSubCategoryWithSlider = async (
  data,
  sliderData,
  setUpdateData
) => {
  try {
    const request = await postData(`/sub_category/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// patch sub category with slider data
export const PatchSubCategoryWithSlider = async (
  id,
  data,
  sliderData,
  setUpdateData
) => {
  try {
    const request = await patchData(`/sub_category/${id}/`, data); // Handle the data
    if (sliderData.length > 0) {
      sliderData.map(async (item) => {
        const data = {
          sub_category: id,
          path: item.path,
          image: item.base64,
        };
        if (item?.state === "new") {
          await postData(`/sub_category_slider/`, data)
            .then((res) => {
              console.log(res);
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (item?.state === "deleted") {
          await deleteData(`/sub_category_slider/${item.id}/`)
            .then((res) => {
              console.log(res);
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (item?.state === "updated") {
          await patchData(`/sub_category_slider/${item.id}/`, data)
            .then((res) => {
              console.log(res);
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// put SubCategory
export const PutSubCategory = async (id, data) => {
  try {
    const request = await putData(`/sub_category/${id}`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// delete SubCategory
export const DeleteSubCategory = async (id) => {
  try {
    const request = await deleteData(`/sub_category/${id}`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};
