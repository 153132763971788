import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Index from "./pages/Index";
import { Box } from "@mui/material";
import { Category } from "./pages/Category";
import { useRecoilState } from "recoil";
import { userInfo } from "./recoil";
import { Company } from "./pages/Company";
import { SubCategory } from "./pages/SubCategory";
import { Product } from "./pages/Product";
import { Order } from "./pages/Order";
import { Support } from "./pages/Support";
import { Files } from "./pages/Files";
import { HomeSlider } from "./pages/HomeSlider";
import { Transactions } from "./pages/Transactions";
import { KeyUsersCount } from "./pages/KeyUsersCount";
import { KeyValidity } from "./pages/KeyValidity";
import { User } from "./pages/User";
import {WholeSale} from "./pages/WholeSale";
import {Notifications} from "./pages/Notifications";
import Slider from "./components/Slidbar/Slidbar";

const RouterApp = () => {
  const [userInfoData, setUserInfoData] = useRecoilState(userInfo);

  return (
    <Box sx={{ mt: 5, ml: 5, mr: 2 }}>
      <Router>
        <Routes>
    
          {!userInfoData ? (
            <Route path="*" element={<Login />} />
          ) : (
            <>
              <Route path="/" element={<Index />} />
              <Route path="/login" element={<Login />} />
              <Route path="/category" element={<Category />} />
              <Route path="/order" element={<Order />} />
              <Route path="/sub_category" element={<SubCategory />} />
              <Route path="/company" element={<Company />} />
              <Route path="/product" element={<Product />} />
              <Route path="/support" element={<Support />} />
              <Route path="/files" element={<Files />} />
              <Route path="/homeSlider" element={<HomeSlider />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/keyUsersCount" element={<KeyUsersCount />} />
              <Route path="/keyValidity" element={<KeyValidity />} />
              <Route path="/users" element={<User />} />
              <Route path="/wholesale" element={<WholeSale />} />
              <Route path="/notifications" element={<Notifications />} />
            </>
          )}
        </Routes>
      </Router>
    </Box>
  );
};

export default RouterApp;
