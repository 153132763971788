import React from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";

const ProductSection = ({ sectionList, setSectionList }) => {
  const handleDataChange = (id, key, value) => {
    const newList = sectionList.map((item, i) => {
      if (item.id === id) {
        return {
          ...item,
          [key]: value,
          state: item?.state === "new" ? "new" : "updated",
        };
      }
      return item;
    });
    setSectionList(newList);
  };

  return (
    <Box>
      {sectionList
        .filter((item) => item.state !== "deleted" && item.state !== "null")
        .map((item, index) => (
          <Box key={item.id}>
            <Typography
              sx={{
                mt: 2,
                mx: 3,
                fontSize: 18,
              }}
              variant="h6"
              gutterBottom
            >
              Section {index + 1}
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box noValidate>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      id={`title-${item.id}`}
                      label="Title"
                      name="title"
                      autoComplete="title"
                      autoFocus
                      defaultValue={item?.title}
                      onChange={(e) => {
                        handleDataChange(item.id, "title", e.target.value);
                      }}
                    />
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      id={`content-${item.id}`}
                      label="Content"
                      name="content"
                      autoComplete="content"
                      defaultValue={item?.content}
                      multiline
                      rows={4}
                      autoFocus
                      onChange={(e) => {
                        handleDataChange(item.id, "content", e.target.value);
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box noValidate>
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      id={`title_ar-${item.id}`}
                      label="Title AR"
                      name="title_ar"
                      autoComplete="Description"
                      autoFocus
                      defaultValue={item?.title_ar}
                      onChange={(e) => {
                        handleDataChange(item.id, "title_ar", e.target.value);
                      }}
                    />

                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      id={`content_ar-${item.id}`}
                      label="Content Ar"
                      name="content_ar"
                      autoComplete="content_ar"
                      defaultValue={item?.content_ar}
                      autoFocus
                      multiline
                      rows={4}
                      onChange={(e) => {
                        handleDataChange(item.id, "content_ar", e.target.value);
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                    }}
                    noValidate
                  >
                    <TextField
                      size="small"
                      margin="normal"
                      fullWidth
                      id={`seq-${item.id}`}
                      label="Seq"
                      name="seq"
                      autoComplete="seq"
                      defaultValue={item?.seq}
                      type="number"
                      inputMode="numeric"
                      sx={{
                        width: "100%",
                      }}
                      onChange={(e) => {
                        handleDataChange(item.id, "seq", e.target.value);
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                    }}
                    noValidate
                  >
                    <Button
                      sx={{
                        width: "100%",
                        backgroundColor: "red",
                        color: "white",
                        borderRadius: "5px",
                        mt: 2,
                      }}
                      variant="contained"
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        const newList = sectionList.map((items, i) => {
                          if (item.id !== items.id) {
                            return items;
                          }
                          if (items.state === "new") {
                            return { ...items, state: "null" };
                          } else {
                            return { ...items, state: "deleted" };
                          }
                        });
                        setSectionList(newList);
                      }}
                    >
                      Remove
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          mt: 3,
        }}
      >
        <Button
          margin="normal"
          component="label"
          variant="contained"
          sx={{ width: "90%", mt: 2 }}
          onClick={() => {
            setSectionList((old) => [
              ...old,
              {
                id: uuidv4(),
                title: "",
                content: "",
                title_ar: "",
                content_ar: "",
                seq: "",
                state: "new",
              },
            ]);
          }}
        >
          Add Section
        </Button>
      </Box>
    </Box>
  );
};

export default ProductSection;
