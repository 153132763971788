import { deleteData, getData, patchData, postData, putData } from "..";

// get Order
export const GetOrderData = async (
  skip,
  take,
  search,
  orderNumber,
  selectedStatus,
  selectedPaymentMethod,
  paymentStatus,
  selectedCreatedFromDate,
  selectedCreatedToDate,
  isView
) => {
  if ((skip !== undefined && take !== undefined) || search !== search) {
    try {
      const request = await getData(
        `/order?page=${
          skip === 0 ? 1 : skip
        }&page_size=${take}&ordering=-created&search=${search}&order_number=${
          orderNumber == undefined ? "" : orderNumber
        }&status=${selectedStatus}&payment_method=${selectedPaymentMethod}&payment_status=${paymentStatus}&is_viewed=${isView}&created_at_after=${selectedCreatedToDate}&created_at_before=${selectedCreatedToDate}`
      ); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// get Order By Id
export const GetOrderByIdData = async (id) => {
  if (id) {
    try {
      const request = await getData(`/order/${id}`); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// post Order
export const PostOrder = async (data) => {
  try {
    const request = await postData(`/order/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// put Order
export const PutOrder = async (id, data) => {
  try {
    const request = await putData(`/order/${id}/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// delete Order
export const DeleteOrder = async (id) => {
  try {
    const request = await deleteData(`/order/${id}`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// patch Order
export const PatchOrderPaid = async (id) => {
  try {
    const request = await patchData(`/order/${id}/mark_as_paid/`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// patch Order
export const PatchOrderApproved = async (id, data) => {
  try {
    const request = await patchData(`/order/${id}/approve_order/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// patch Order
export const PatchOrderRejected = async (id, data) => {
  try {
    const request = await patchData(`/order/${id}/reject_order/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// patch Order
export const PatchOrderReturn = async (id) => {
  try {
    const request = await patchData(`/order/${id}/return_order/`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// get Order for home
export const GetOrderDataHome = async (skip, take, search) => {
  if ((skip !== undefined && take !== undefined) || search !== search) {
    try {
      const request = await getData(
        `/order?page=${skip === 0 ? 1 : skip}&page_size=${take}`
      ); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};
