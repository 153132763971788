import { deleteData, getData, postData, putData } from "..";

// get WholeSale
export const GetWholeSaleData = async (skip, take, search) => {
    if (skip !== undefined && take !== undefined || search !== search) {
        try {
            const request = await getData(`/auth/wholesale_user_type/?page=${skip === 0 ? 1 : skip || take}&page_size=${take}&search=${search}`); // Handle the data
            return request;
        } catch (error) {
            console.error(error); // Handle the error
            throw error;
        }
    }
};

// get WholeSale By Id
export const GetWholeSaleByIdData = async (id) => {
    if (id) {
        try {
            const request = await getData(`/auth/wholesale_user_type/${id}`); // Handle the data
            return request;
        } catch (error) {
            console.error(error); // Handle the error
            throw error;
        }
    }
};

// post WholeSale
export const PostWholeSale = async (data) => {
    try {
        const request = await postData(`/auth/wholesale_user_type/`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

// put WholeSale
export const PutWholeSale = async (id, data) => {
    try {
        const request = await putData(`/auth/wholesale_user_type/${id}/`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

// delete WholeSale
export const DeleteWholeSale = async (id) => {
    try {
        const request = await deleteData(`/auth/wholesale_user_type/${id}`); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

