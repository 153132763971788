import React, { useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useRecoilState } from "recoil";
import { selectedKeyValidityUnitState } from "../../recoil/select";

const SelectKeyVelidityUnit = (props) => {
  const [selectedUnit, setSelectedUnit] = useRecoilState(selectedKeyValidityUnitState);

  const handleChange = (event) => {
    setSelectedUnit(event.target.value);
  };


  const data = [ "day", "week", "month", "year", "lifetime" ];

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <FormControl fullWidth margin="normal" sx={{ minWidth: 300 }}>
      <InputLabel id="status-label" sx={{ background: "#fff",top: -6 }}>
        Select Unit
      </InputLabel>
      <Select
        size="small"
        sx={{ minWidth: 300 }}
        labelId="status-label"
        id="status-select"
        onChange={handleChange}
        value={selectedUnit}
      >
        {data?.map((status, index) => (
          <MenuItem key={index} value={status}>
           {capitalizeFirstLetter(status)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectKeyVelidityUnit;
