import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useRecoilState } from "recoil";
import { selectedUserState } from "../../recoil/select";
import { GetUserData } from "../../services/api/user";

const SelectedUser = (props) => {
  const [selectedUser, setSelectedUser] = useRecoilState(selectedUserState);
  const [users, setUsers] = useState([]);

  const handleChange = (event) => {
    setSelectedUser(event.target.value);
    console.log(event.target.value);
  };

  const fetchData = () => {
    GetUserData(0, 10000, "")
      .then((res) => {
        setUsers(res.results);
      })
      .catch(() => {
        console.log("error");
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl fullWidth margin="normal" sx={{ minWidth: 300 }}>
      <InputLabel id="status-label" sx={{ background: "#fff", top: -6 }}>
        Select Transaction Type
      </InputLabel>
      <Select
        size="small"
        sx={{ minWidth: 300 }}
        labelId="status-label"
        id="status-select"
        onChange={handleChange}
        value={selectedUser}
      >
        {users?.map((item, index) => (
          <MenuItem key={index} value={item.pk}>
            {item.username} / {item.email}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectedUser;
