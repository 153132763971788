import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { useRecoilState } from "recoil";
import { openFilterState } from '../../recoil/global';
import { TextField, Typography } from '@mui/material';
import SelectStatus from '../Selects/SelectStatus';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { selectedCreatedFromDateState, selectedCreatedToDateState, selectedIsViewState, selectedPaymentMethodState, selectedPaymentStatus, selectedStatusState } from '../../recoil/select';
import { orderNumberState } from '../../recoil/order';
import { dataTabelState } from '../../recoil/Tabel';
import SelectPaymentMethod from '../Selects/SelectPaymentMethod';
import SelectPaymentStatus from '../Selects/SelectPaymentStatus';
import SelectisView from '../Selects/SelectIsView';
import SelectCreatedFromDate from '../Selects/SelectCreatedFromDate';
import SelectCreatedToDate from '../Selects/SelectCreatedToDate';

export default function Filter(props) {
  const [state, setState] = useRecoilState(openFilterState);
  const [selectedStatus, setSelectedStatus] = useRecoilState(selectedStatusState);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useRecoilState(selectedPaymentMethodState);
  const [paymentStatus, setPaymentStatus] = useRecoilState(selectedPaymentStatus);
  const [selectedCreatedFromDate, setSelectedCreatedFromDate] = useRecoilState(selectedCreatedFromDateState);
  const [selectedCreatedToDate, setSelectedCreatedToDate] = useRecoilState(selectedCreatedToDateState);
  const [isView, setIsView] = useRecoilState(selectedIsViewState);

  const [dataState, setDataState] = useRecoilState(dataTabelState);

  const handleSearch = () => {
    if (dataState.filters.search || dataState.filters.order_number ||selectedStatus || selectedPaymentMethod || paymentStatus || selectedCreatedFromDate || selectedCreatedToDate || isView) {
      props.fetchData();
    }
  }

  const handleRemoveSearch = () => {
    setSelectedStatus('');
    setSelectedPaymentMethod('');
    setPaymentStatus('');
    setSelectedCreatedFromDate('');
    setSelectedCreatedToDate('');
    setIsView('');
    setDataState((old) => ({
      ...old,
      filters: {
        ...old.filters,
        search: '',
        order_number: '',
      },
    }));

    props.fetchData();
  }
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 350, mt: 8 }}
      role="presentation"
    >
      <Box sx={{ m: 1, position: 'relative', height: '86vh' }}>
        <Typography mt={2} variant='h6'>Filter Advancis</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            value={dataState.filters.order_number}
            sx={{
              borderColor: "#fff",
              borderRadius: "12px",
            }}
            onChange={(e) => {
              setDataState((old) => ({
                ...old,
                filters: {
                  ...old.filters,
                  order_number: e.target.value,
                },
              }));
            }}
            size="small"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                props.fetchData();
              }
            }}
            placeholder="Order Number"
            label="Order Number"
            fullWidth
          />
        </Box>
        <SelectPaymentMethod />
        <SelectPaymentStatus />
        <SelectisView />
        <SelectStatus />
        <SelectCreatedFromDate />
        <SelectCreatedToDate />
        <Box sx={{ position: 'absolute', bottom: 0, right: 0, left: 0 }}>
          <Button onClick={() => handleSearch()} sx={{ width: '100%' }} variant="outlined" startIcon={<SearchIcon />}>
            Search
          </Button>
          <Button onClick={() => handleRemoveSearch()} color="error" sx={{ width: '100%', mt: 1 }} variant="outlined" startIcon={<DeleteIcon />}>
            Remove
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={'right'} className="mt-5 pt-5">
        <Drawer
          anchor={'right'}
          open={state['right']}
          onClose={toggleDrawer('right', false)}
        >
          {list('right')}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
