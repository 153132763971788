import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { openModalShow } from "../../../recoil/modal";
import { dataClickItem } from "../../../recoil/global";
import GeneralModal from "../../GeneralModal/GeneralModal";
import "leaflet/dist/leaflet.css";
import TabelLine from "../TabelLine";

export default function ModalShow() {
  const [open, setOpen] = useRecoilState(openModalShow);
  const [loading, setLoading] = useState(false);

  function formatDate(inputDate) {
    let formattedDate = `${inputDate.split("T")[0]}`;
    return formattedDate;
  }
  function formatHours(inputDate) {
    let date = new Date(inputDate);
    let hours = date.getHours();
    let minutes = String(date.getMinutes()).padStart(2, "0");
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    let formattedDate = `${hours}:${minutes} ${period}`;
    return formattedDate;
  }
  const dataItem = useRecoilValue(dataClickItem);

  return (
    <GeneralModal
      maxWidth="md"
      open={open}
      title={"Order " + dataItem?.order_number}
      btnFooterHide={true}
      loading={loading}
    >
      <Box sx={{ mx: 2, my: 2 }}>
        <Typography sx={{ mx: 2 }} variant="h5" fontSize={18} fontWeight={500}>
          Created by
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="fullName"
                label="fullName"
                name="fullName"
                autoComplete="fullName"
                value={
                  dataItem?.created_by?.first_name +
                  " " +
                  dataItem?.created_by?.last_name
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Phone"
                name="phone"
                autoComplete="phone"
                value={dataItem?.created_by?.phone}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="email"
                label="email"
                name="email"
                autoComplete="email"
                value={dataItem?.created_by?.email}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="is_wholesale"
                label="Is Wholesale"
                name="is_wholesale"
                autoComplete="is_wholesale"
                value={`${dataItem?.created_by?.is_wholesale} ${
                  dataItem?.created_by?.wholesale_type != null
                    ? " (" + dataItem?.created_by?.wholesale_type + ")"
                    : ""
                }`}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="wallet_balance"
                label="Wallet Balance IQD"
                name="wallet_balance"
                autoComplete="wallet_balance"
                value={dataItem?.created_by?.wallet_balance}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="country"
                label="Country"
                name="country"
                autoComplete="country"
                value={dataItem?.created_by?.country}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="city"
                label="City"
                name="city"
                autoComplete="city"
                value={dataItem?.created_by?.city}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mx: 2, my: 2 }}>
        <Typography sx={{ mx: 2 }} variant="h5" fontSize={18} fontWeight={500}>
          Order Information
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="country"
                label="Country"
                name="country"
                autoComplete="country"
                value={dataItem?.country}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="city"
                label="City"
                name="city"
                autoComplete="city"
                value={dataItem?.city}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="address"
                label="Address"
                name="address"
                autoComplete="address"
                value={dataItem?.address}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="status"
                label="Status"
                name="status"
                autoComplete="status"
                value={dataItem?.status}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="payment_method"
                label="payment_method"
                name="payment_method"
                autoComplete="payment_method"
                value={dataItem?.payment_method}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="payment_status"
                label="Payment Status"
                name="payment_status"
                autoComplete="payment_status"
                value={dataItem?.payment_status}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="is_viewed"
                label="Is Viewed"
                name="is_viewed"
                autoComplete="is_viewed"
                value={dataItem?.is_viewed}
              />
            </Box>
          </Grid>
          {dataItem?.transaction_url && (
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  mx: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <TextField
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  id="transaction_url"
                  label="Transaction URL"
                  name="transaction_url"
                  autoComplete="transaction_url"
                  value={dataItem?.transaction_url}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="use_wallet"
                label="Use Wallet"
                name="use_wallet"
                autoComplete="use_wallet"
                value={dataItem?.use_wallet}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="paid_amount_from_wallet"
                label="Paid amount from wallet"
                name="paid_amount_from_wallet"
                autoComplete="paid_amount_from_wallet"
                value={dataItem?.paid_amount_from_wallet}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                id="total_price"
                label="Total Price"
                name="total_price"
                autoComplete="total_price"
                value={dataItem?.total_price}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mx: 2, my: 2 }}>
        <Typography sx={{ mx: 2 }} variant="h5" fontSize={18} fontWeight={500}>
          Order Line
        </Typography>
        <TabelLine data={dataItem} />
      </Box>
      <Box sx={{ mx: 2, my: 2 }}>
        <Typography sx={{ mx: 2 }} variant="h5" fontSize={18} fontWeight={500}>
          Order Activite
        </Typography>
        {/* Paid */}
        <Grid container>
          {dataItem?.paid_by && (
            <>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="paid_by"
                    label="Paid by"
                    name="paid_by"
                    autoComplete="paid_by"
                    value={
                      dataItem?.paid_by?.first_name +
                      " " +
                      dataItem?.paid_by?.last_name
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="paid_at"
                    label="Paid at"
                    name="paid_at"
                    autoComplete="paid_at"
                    value={
                      formatDate(dataItem?.paid_at) +
                      " - " +
                      formatHours(dataItem?.paid_at)
                    }
                  />
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        {/* Approved */}
        <Grid container>
          {dataItem?.approved_by && (
            <>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="approved_by"
                    label="Approved by"
                    name="approved_by"
                    autoComplete="approved_by"
                    value={
                      dataItem?.approved_by?.first_name +
                      " " +
                      dataItem?.approved_by?.last_name
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="approved_at"
                    label="Approved at"
                    name="approved_at"
                    autoComplete="approved_at"
                    value={
                      formatDate(dataItem?.approved_at) +
                      " - " +
                      formatHours(dataItem?.approved_at)
                    }
                  />
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        {/* Rejected */}
        <Grid container>
          {dataItem?.rejected_by && (
            <>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="rejected_by"
                    label="Rejected by"
                    name="rejected_by"
                    autoComplete="rejected_by"
                    value={
                      dataItem?.rejected_by?.first_name +
                      " " +
                      dataItem?.rejected_by?.last_name
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="rejected_at"
                    label="Rejected at"
                    name="rejected_at"
                    autoComplete="rejected_at"
                    value={
                      formatDate(dataItem?.rejected_at) +
                      " - " +
                      formatHours(dataItem?.rejected_at)
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="rejected_notes"
                    label="Rejected Note"
                    name="rejected_notes"
                    autoComplete="rejected_notes"
                    value={dataItem?.rejected_notes}
                  />
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        {/* returned */}
        <Grid container>
          {dataItem?.returned_by && (
            <>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="returned_by"
                    label="Returned by"
                    name="returned_by"
                    autoComplete="returned_by"
                    value={
                      dataItem?.returned_by?.first_name +
                      " " +
                      dataItem?.returned_by?.last_name
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="returned_at"
                    label="Returned at"
                    name="returned_at"
                    autoComplete="returned_at"
                    value={
                      formatDate(dataItem?.returned_at) +
                      " - " +
                      formatHours(dataItem?.returned_at)
                    }
                  />
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </GeneralModal>
  );
}
