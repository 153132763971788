import React from 'react'
import { Box, Typography } from '@mui/material';
import Slider from '../components/Slidbar/Slidbar';
import Dashboard from '../components/Dashboard/Dashboard';

const Index = () => {

    return (
        <Box>
                <Slider />
            <Box
                container
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: 38,
                    mb: 3,
                    mt: 5,
                    mx:2
                }}
            >
                <Box
                    sx={{
                        display: 'flex'
                    }}>
                    <Typography variant="h3" gutterBottom fontSize={34} fontWeight={600}>
                        Dashboard
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ mb: 2 }}>
                <Dashboard />
            </Box>
        </Box>
    )
}

export default Index;