import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useRecoilState } from 'recoil';
import { selectedStatusState } from '../../recoil/select';

const SelectStatus = (props) => {
    const [selectedStatus, setSelectedStatus] = useRecoilState(selectedStatusState);

    const handleChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const data = ['', 'pending', 'rejected', 'approved', 'returned', 'canceled']

    return (
        <FormControl fullWidth margin="normal" sx={{ minWidth: 300 }}>
            <InputLabel id="status-label" sx={{ background: '#fff' }}>
                Select Status
            </InputLabel>
            <Select
                size='small'
                sx={{ minWidth: 300 }}
                labelId="status-label"
                id="status-select"
                onChange={handleChange}
                value={selectedStatus}
            >
                {data?.map((status, index) => (
                    <MenuItem key={index} value={status}>
                        {status}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectStatus;
