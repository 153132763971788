// get reports data
import { deleteData, getData, postData, putData } from "..";

export const GetReportsApi = async () => {
  try {
    const request = await getData(
      `/product/stats/`
    ); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};
