import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { selectedCreatedFromDateState } from '../../recoil/select';

const SelectCreatedFromDate = (props) => {
    const [selectedCreatedFromDate, setSelectedCreatedFromDate] = useRecoilState(selectedCreatedFromDateState);
    const [year, setYear] = React.useState('');
    const [month, setMonth] = React.useState('');
    const [day, setDay] = React.useState('');

    const handleChange = () => {
        if(year !== '' && month !== '' && day !== ''){
            setSelectedCreatedFromDate(`${year}-${month}-${day}`);
        }
        else {
            setSelectedCreatedFromDate('')
        }
    };

    useEffect(() => {
        handleChange();
    }, [year, month, day])

    useEffect(() => {
        setTimeout(() => {
            if (selectedCreatedFromDate === '') {
                setYear('');
                setMonth('');
                setDay('');
            }
        }, 100);
    }, [selectedCreatedFromDate])

    const data_year = ['2024', '2025', '2026', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034', '2035', '2036', '2037', '2038', '2039', '2040'];
    const data_month = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const data_day = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];


    return (
        <Box fullWidth margin="normal" sx={{ minWidth: 300 }}>
            <Typography sx={{ background: '#fff', my: 1 }}>
                Created From
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControl>
                    <InputLabel id="item-1-label" sx={{ background: '#fff' }}>
                        Year
                    </InputLabel>
                    <Select
                        size='small'
                        sx={{ minWidth: 90, marginRight: 4 }}
                        labelId="item-1-label"
                        placeholder='Year'
                        id="item-1-select"
                        onChange={(e) => setYear(e.target.value)}
                        value={year}
                    >
                        {data_year?.map((item, index) => (
                            <MenuItem key={index} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="item-2-label" sx={{ background: '#fff' }}>
                        Month
                    </InputLabel>
                    <Select
                        size='small'
                        sx={{ minWidth: 90, marginRight: 4 }}
                        labelId="item-2-label"
                        id="item-2-select"
                        placeholder='Month'
                        onChange={(e) => setMonth(e.target.value)}
                        value={month}
                    >
                        {data_month?.map((item, index) => (
                            <MenuItem key={index} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="item-3-label" sx={{ background: '#fff' }}>
                        Day
                    </InputLabel>
                    <Select
                        size='small'
                        sx={{ minWidth: 90, marginRight: 4 }}
                        labelId="item-3-label"
                        id="item-3-select"
                        onChange={(e) => setDay(e.target.value)}
                        value={day}
                    >
                        {data_day?.map((item, index) => (
                            <MenuItem key={index} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );
};

export default SelectCreatedFromDate;
