import { deleteData, getData, patchData, postData, putData } from "..";

// get User
export const GetUserData = async (skip, take, search) => {
  if ((skip !== undefined && take !== undefined) || search !== search) {
    try {
      const request = await getData(
        `/auth/admin/users/?page=${
          skip === 0 ? 1 : skip || take
        }&page_size=${take}&search=${search}`
      ); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// get User By Id
export const GetUserByIdData = async (id) => {
  if (id) {
    try {
      const request = await getData(`/auth/admin/users/${id}`); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// post User
export const PostUser = async (data) => {
  try {
    const request = await postData(`/auth/admin/users/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};
// set super user / admin
export const SetAdminUser = async (id) => {
  try {
    const request = await getData(`/auth/admin/users/${id}/set-superuser/`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};
// unset super user / admin
export const UnsetAdminUser = async (id) => {
  try {
    const request = await getData(`/auth/admin/users/${id}/unset-superuser/`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// put User
export const PutUser = async (id, data) => {
  try {
    const request = await putData(`/auth/admin/users/${id}/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// patch User
export const PatchUser = async (id, data) => {
  try {
    const request = await patchData(`/auth/admin/users/${id}/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// delete User
export const DeleteUser = async (id) => {
  try {
    const request = await deleteData(`/auth/admin/users/${id}`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};
