import { atom } from "recoil";

// save selectt data
export const dataSelectState = atom({
  key: "dataSelectState",
  default: false,
});

// selected state Status
export const selectedStatusState = atom({
  key: "selectedStatusState",
  default: "",
});

// selected state curency
export const selectedCurencyState = atom({
  key: "selectedCurencyState",
  default: "IQD",
});

// selected state curency
export const selectedExpiredState = atom({
  key: "selectedExpiredState",
  default: "",
});

// selected state Tags
export const selectedTagsState = atom({
  key: "selectedTagsState",
  default: "",
});

// selected state Company
export const selectedCompanyState = atom({
  key: "selectedCompanyState",
  default: "",
});

// selected state Category
export const selectedCategoryState = atom({
  key: "selectedCategoryState",
  default: "",
});

// selected state SubCategory
export const selectedSubCategoryState = atom({
  key: "selectedSubCategoryState",
  default: "",
});

// selected state Payment Method
export const selectedPaymentMethodState = atom({
  key: "selectedPaymentMethodState",
  default: "",
});

// selected state Is View
export const selectedIsViewState = atom({
  key: "selectedIsViewState",
  default: "",
});

export const selectedKeyUserCountState = atom({
  key: "selectedKeyUserCountState",
  default: "",
});

export const selectedKeyValidityState = atom({
  key: "selectedKeyValidityState",
  default: "",
});

export const selectedTransactionTypeState = atom({
  key: "selectedTransactionTypeState",
  default: "",
});

export const selectedUserState = atom({
  key: "selectedUserState",
  default: "",
});

export const selectedPaymentStatus = atom({
  key: "selectedPaymentStatus",
  default: "",
});

export const selectedCreatedFromDateState = atom({
  key: "selectedCreatedFromDateState",
  default: "",
});

export const selectedCreatedToDateState = atom({
  key: "selectedCreatedToDateState",
  default: "",
});
export const selectedKeyValidityUnitState = atom({
  key: "selectedKeyValidityUnitState",
  default: "",
});

export const dataSelectGenderState = atom({
  key: "dataSelectGenderState",
  default: "Male",
});

export const selectedWholeSaleState = atom({
  key: "selectedWholeSaleState",
  default: "",
});

export const selectedCityState = atom({
  key: "selectedCityState",
  default: "",
});

export const selectedNotificationLevelState = atom({
  key: "selectedNotificationLevelState",
  default: "",
});
