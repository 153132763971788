import { deleteData, getData, patchData, postData, putData } from "..";

// get Category
export const GetCategoryData = async (
  skip,
  take,
  search,
  createdAfter,
  createdBefor
) => {
  if ((skip !== undefined && take !== undefined) || search !== search) {
    try {
      const request = await getData(
        `/category?page=${
          skip === 0 ? 1 : skip || take
        }&page_size=${take}&search=${search}${
          createdAfter ? `&created_after=${createdAfter}` : ""
        }${createdBefor ? `&created_before=${createdBefor}` : ""}`
      ); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// get Category By Id
export const GetCategoryByIdData = async (id) => {
  if (id) {
    try {
      const request = await getData(`/admin/Category/${id}`); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// post Category
export const PostCategory = async (data) => {
  try {
    const request = await postData(`/category/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// post with slider data

export const PostCategoryWithSlider = async (
  data,
  sliderData,
  setUpdateData
) => {
  try {
    const request = await postData(`/category/`, data); // Handle the data
    console.log('sliderData', sliderData)
    if (sliderData?.length > 0 && sliderData !== null && sliderData !== undefined) {
      sliderData?.map(async (item) => {
        const dataSlider = {
          category: request?.id,
          image: item?.base64,
          path: item?.path,
        };
        if (item?.state === "new") {
          await postData(`/category_slider/`, dataSlider)
            .then((res) => {
              setUpdateData(Math.random());
              return res
            })
            .catch((err) => {
              console.log(err);
              return err
            });
        }
      });
    }
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// patch Category with slider

export const PatchCategoryWithSlider = async (
  id,
  data,
  sliderData,
  setUpdateData
) => {
  try {
    const request = await patchData(`/category/${id}/`, data); // Handle the data
    if (sliderData.length > 0) {
      sliderData.map(async (item) => {
        const data = {
          category: id,
          image: item?.base64,
          path: item?.path || "",
        };
        if (item?.state === "new") {
          await postData(`/category_slider/`, data)
            .then((res) => {
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (item?.state === "deleted") {
          await deleteData(`/category_slider/${item.id}/`)
            .then((res) => {
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (item?.state === "updated") {
          await putData(`/category_slider/${item.id}/`, data)
            .then((res) => {
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// put Category
export const PutCategory = async (id, data) => {
  try {
    const request = await putData(`/category/${id}/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// delete Category
export const DeleteCategory = async (id) => {
  try {
    const request = await deleteData(`/category/${id}`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};
