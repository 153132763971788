import { useEffect } from "react";
import {
    Avatar,
    Box,
} from "@mui/material";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ModalInfo from "./Modal/ModalInfo";
import { dataClickItem, methodRequestStatus, openFilterState, updateDataState } from "../../recoil/global";
import { confirmModalState, openModalDelete, openModalInfo } from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { useState } from "react";
import { dataTabelState } from "../../recoil/Tabel";
import Filters from "./Filters";
import { selectedStatusState } from "../../recoil/select";
import { GetCategoryData } from "../../services/api/category";

export const Tabel = () => {
    const [data, setData] = useRecoilState(dataTabelState);
    const [createdAfter, setCreatedAfter] = useState(null);
    const [createdBefor, setCreatedBefor] = useState(null);

    const selectedStatus = useRecoilValue(selectedStatusState);
    const state = useRecoilValue(openFilterState);
    const updateData = useRecoilValue(updateDataState);

    const resetConfirm = useResetRecoilState(confirmModalState);

    const setDataItem = useSetRecoilState(dataClickItem);
    const setOpenModal = useSetRecoilState(openModalInfo);
    const setOpenDelete = useSetRecoilState(openModalDelete);
    const setMethodRequest = useSetRecoilState(methodRequestStatus);
    useEffect(() => {
        setDataItem(null)
    }, [state])

    const handelEdit = (data) => {
        setOpenModal(true);
        setDataItem(data);
        setMethodRequest('edit');
    };

    const handelDelete = (data) => {
        setOpenDelete(true);
        setDataItem(data);
        setMethodRequest('delete');
    };

    const columns = [
        {
            field: "name",
            headerName: "Name",
            headerClassName: "grid--header",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                        <Avatar sx={{
                            borderRadius: 0
                        }} sizes="small" alt="Image" src={params.row?.image_file} />
                        <ColumnText text={params.row?.name ? params.row?.name : "N/A"} variant="subtitle1" fontWeight="bold" />
                        {/* <ColumnText text={params.row?.job_title?.name_ar} name_ar="caption" color={"gray"} /> */}
                    </Box>
                );
            },
        },
        {
            field: "name_ar",
            headerName: "Name AR",
            headerClassName: "grid--header",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                        <ColumnText text={params.row?.name ? params.row?.name_ar : "N/A"} variant="subtitle1" fontWeight="bold" />
                    </Box>
                );
            },
        },
        {
            field: "category_slider_items",
            headerName: "Slider",
            headerClassName: "grid--header",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                        <ColumnText text={params.row?.category_slider_items?.length > 0 ? params.row?.category_slider_items?.length : "N/A"} variant="subtitle1" fontWeight="bold" />
                    </Box>
                );
            },
        },
        {
            field: "sub_categories",
            headerName: "Sub Categories",
            headerClassName: "grid--header",
            flex: 1,
            renderCell: (params) => {
                return (
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                        <ColumnText text={params.row?.sub_categories?.length > 0 ? params.row?.sub_categories?.length : "N/A"} variant="subtitle1" fontWeight="bold" />
                    </Box>
                );
            },
        },
        {
            field: "operations",
            headerName: "Operations",
            flex: 1,
            headerClassName: "grid--header",
            renderCell: (params) => {
                return (
                    <Box display={"flex"} alignItems={"center"} gap={2}>
                        <ColumnButton onClick={() => handelEdit(params.row)} type={'edit'} />
                        <ColumnButton onClick={() => handelDelete(params.row)} type={'delete'} />
                    </Box>
                );
            },
        },
    ];

    const fetchData = () => {
        setData((old) => ({
            ...old,
            loading: true,
            data: [],
        }));
        GetCategoryData(data.filters.skip, data.filters.take, data.filters.search, createdAfter, createdBefor).then((res) => {
            console.log("Dtest", res)
            setData((old) => ({
                ...old,
                data: res.results,
                total: res?.count,
                loading: false,
            }))
        })
            .catch(() => {
                setData({ ...data, loading: false })
            });
    };

    let filters = data.filters.page ||
        data.filters.take ||
        data.filters.skip;

    useEffect(() => {
        fetchData()
    }, [filters, updateData,createdAfter,createdBefor])

    return (
        <>
            <Box m={2}>
                <HeaderGridTabel title="Categores"
                    icon={
                        <HomeWorkIcon size={25} className="color-primary" color={"#08001e"} />
                    }
                    btn={"Add Category"}
                    fetchData={fetchData}
                    isFilter={true}
                />
                <Box mt={2}>
                    <DataGridTabel columns={columns} data={data} setData={setData} />
                </Box>
                {/* Modal */}
                <ModalInfo />
                <ModalDelete />
                {/* Fillter */}
                <Filters
                    createdAfter={createdAfter}
                    setCreatedAfter={setCreatedAfter}
                    createdBefor={createdBefor}
                    setCreatedBefor={setCreatedBefor}
                    fetchData={fetchData} />
            </Box>
        </>
    );
};

export default Tabel;
