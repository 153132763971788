import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useRecoilState } from 'recoil';
import { selectedPaymentMethodState } from '../../recoil/select';

const SelectPaymentMethod = (props) => {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useRecoilState(selectedPaymentMethodState);

    const handleChange = (event) => {
        setSelectedPaymentMethod(event.target.value);
    };

    const data = ['', 'cash', 'zain_cash', 'fast_pay', 'credit_card', 'fib']

    return (
        <FormControl fullWidth margin="normal" sx={{ minWidth: 300 }}>
            <InputLabel id="paymentMethod-label" sx={{ background: '#fff' }}>
                Select Payment Method
            </InputLabel>
            <Select
                size='small'
                sx={{ minWidth: 300 }}
                labelId="paymentMethod-label"
                id="paymentMethod-select"
                onChange={handleChange}
                value={selectedPaymentMethod}
            >
                {data?.map((paymentMethod, index) => (
                    <MenuItem key={index} value={paymentMethod}>
                        {paymentMethod}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectPaymentMethod;
