import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { openModalInfo } from "../../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../../recoil/global";
import {
  dataSelectCityState,
  dataSelectProvinceState,
  selectedJobFieldState,
  dataSelectGenderState,
  selectedWholeSaleState,
  selectedCityState,
} from "../../../recoil/select";
import GeneralModal from "../../GeneralModal/GeneralModal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "leaflet/dist/leaflet.css";
import { posisionMapState } from "../../../recoil/location";
import {
  PostUser,
  PutUser,
  PatchUser,
  SetAdminUser,
  UnsetAdminUser,
} from "../../../services/api/user";
import MultiImage from "../../Others/MultiImage";
import SelectGender from "../../Selects/SelectGender";
import SelectWholeSale from "../../Selects/SelectWholeSale";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SelectCity from "../../Selects/SelectCity";
import { GetWholeSaleData } from "../../../services/api/wholeSale";

export default function ModalInfo() {
  const [open, setOpen] = useRecoilState(openModalInfo);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [sendData, setSendData] = useState(false);
  const [imgList, setImgList] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectGender, setSelectGender] = useRecoilState(dataSelectGenderState);
  const [selectedWholeSale, setSelectedWholeSale] = useRecoilState(
    selectedWholeSaleState
  );
  const [selectedCity, setSelectedCity] = useRecoilState(selectedCityState);

  const dataItem = useRecoilValue(dataClickItem);
  const methodRequest = useRecoilValue(methodRequestStatus);

  const setUpdateData = useSetRecoilState(updateDataState);

  const regions = [
    "al_anbar",
    "al_basra",
    "al_muthanna",
    "an_najaf",
    "al_qadisiyah",
    "al_sulaymaniyah",
    "babil",
    "baghdad",
    "dhi_qar",
    "dyala",
    "dohuk",
    "erbil",
    "karbala",
    "kirkuk",
    "maysan",
    "ninawa",
    "salah_ad_din",
    "wasit",
  ];

  useEffect(() => {
    if (open) {
      dataItem?.gender && setSelectGender(dataItem?.gender);
      dataItem?.wholesale_type
        ? setSelectedWholeSale(dataItem?.wholesale_type)
        : setSelectedWholeSale(-1);
      dataItem?.city && setSelectedCity(dataItem?.city);
      dataItem?.is_active
        ? setIsActive(dataItem?.is_active)
        : setIsActive(false);
      dataItem?.is_superuser
        ? setIsAdmin(dataItem?.is_superuser)
        : setIsAdmin(false);
    }
  }, [dataItem, open]);

  // send request
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoading(true);
    // function api request
    if (methodRequest === "add") {
      // data form
      const formData = new FormData();

      const infoData = {
        username: data.get("username"),
        first_name: data.get("first_name"),
        last_name: data.get("last_name"),
        email: data.get("email"),
        birth_date: data.get("birthday")
          ? new Date(data.get("birthday"))?.toISOString()?.split("T")[0]
          : null,
        phone: data.get("phone"),
        city: selectedCity,
        country: "iraq",
        wallet_balance: data.get("wallet_balance"),
        wholesale_type:
          Number(selectedWholeSale) === -1 ? null : selectedWholeSale,
        is_wholesale: selectedWholeSale === "-1" ? false : true,
        is_active: isActive,
      };

      console.log(infoData);
      !infoData.birth_date && delete infoData.birth_date;
      !regions.includes(infoData.city) && delete infoData.city;

      // function api request
      PostUser(infoData)
        .then((res) => {
          console.log(res);
          if (isAdmin) {
            SetAdminUser(res.data.user.pk)
              .then((res) => {
                toast.success("Added successfully");
                handleClose();
                setUpdateData(Math.random());
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
                if (err.response) {
                  const errorData = err.response.data;

                  if (
                    typeof errorData === "object" &&
                    !Array.isArray(errorData)
                  ) {
                    // If error data is an object, iterate over its keys
                    Object.keys(errorData).forEach((key) => {
                      const messages = errorData[key];
                      if (Array.isArray(messages)) {
                        messages.forEach((message) => {
                          toast.error(key + ": " + message);
                        });
                      } else {
                        toast.error(key + ": " + messages);
                      }
                    });
                  } else if (typeof errorData.message === "string") {
                    // If there's a 'message' field, display it directly
                    toast.error(errorData.message);
                  } else {
                    // Handle other types of error data as needed
                    toast.error("Unexpected error occurred");
                  }
                } else {
                  // If there's no response from the server
                  toast.error("Check your internet connection");
                }
              });
          } else {
            toast.success("Added successfully");
            handleClose();
            setUpdateData(Math.random());
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            const errorData = err.response.data;

            if (typeof errorData === "object" && !Array.isArray(errorData)) {
              // If error data is an object, iterate over its keys
              Object.keys(errorData).forEach((key) => {
                const messages = errorData[key];
                if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                    toast.error(key + ": " + message);
                  });
                } else {
                  toast.error(key + ": " + messages);
                }
              });
            } else if (typeof errorData.message === "string") {
              // If there's a 'message' field, display it directly
              toast.error(errorData.message);
            } else {
              // Handle other types of error data as needed
              toast.error("Unexpected error occurred");
            }
          } else {
            // If there's no response from the server
            toast.error("Check your internet connection");
          }
        });
    } else {
      const data = new FormData(event.currentTarget);
      // data form
      const formData = new FormData();

      const infoData = {
        first_name: data.get("first_name"),
        last_name: data.get("last_name"),
        email: data.get("email"),
        birth_date: new Date(data.get("birthday")).toISOString().split("T")[0],
        phone: data.get("phone"),
        city: selectedCity,
        country: "iraq",
        wallet_balance: data.get("wallet_balance"),
        wholesale_type:
          Number(selectedWholeSale) === -1 ? null : selectedWholeSale,
        is_wholesale: Number(selectedWholeSale) === -1 ? false : true,
        is_active: isActive,
      };
      console.log("dataItem", dataItem);
      const idItem = dataItem?.pk;
      PatchUser(idItem, infoData)
        .then((res) => {
          if (dataItem?.is_superuser && !isAdmin) {
            UnsetAdminUser(idItem)
              .then((res) => {
                console.log(res);
                toast.success("Edit successfully");
                handleClose();
                setUpdateData(Math.random());
                setSendData(true);
                setLoading(false);
              })
              .catch((err) => {
                console.log(err)
                if (err.response) {
                  const errorData = err.response.data;

                  if (
                    typeof errorData === "object" &&
                    !Array.isArray(errorData)
                  ) {
                    // If error data is an object, iterate over its keys
                    Object.keys(errorData).forEach((key) => {
                      const messages = errorData[key];
                      if (Array.isArray(messages)) {
                        messages.forEach((message) => {
                          toast.error(key + ": " + message);
                        });
                      } else {
                        toast.error(key + ": " + messages);
                      }
                    });
                  } else if (typeof errorData.message === "string") {
                    // If there's a 'message' field, display it directly
                    toast.error(errorData.message);
                  } else {
                    // Handle other types of error data as needed
                    toast.error("Unexpected error occurred");
                  }
                } else {
                  // If there's no response from the server
                  toast.error("Check your internet connection");
                }
                setLoading(false);
              });
          } else if (!dataItem?.is_superuser && isAdmin) {
            SetAdminUser(idItem)
              .then((res) => {
                toast.success("Added successfully");
                handleClose();
                setUpdateData(Math.random());
                setLoading(false);
              })
              .catch((err) => {
                console.log(err)
                setLoading(false);
                if (err.response) {
                  const errorData = err.response.data;

                  if (
                    typeof errorData === "object" &&
                    !Array.isArray(errorData)
                  ) {
                    // If error data is an object, iterate over its keys
                    Object.keys(errorData).forEach((key) => {
                      const messages = errorData[key];
                      if (Array.isArray(messages)) {
                        messages.forEach((message) => {
                          toast.error(key + ": " + message);
                        });
                      } else {
                        toast.error(key + ": " + messages);
                      }
                    });
                  } else if (typeof errorData.message === "string") {
                    // If there's a 'message' field, display it directly
                    toast.error(errorData.message);
                  } else {
                    // Handle other types of error data as needed
                    toast.error("Unexpected error occurred");
                  }
                } else {
                  // If there's no response from the server
                  toast.error("Check your internet connection");
                }
              });
          }
        })
        .catch((err) => {
          if (err.response) {
            const errorData = err.response.data;

            if (typeof errorData === "object" && !Array.isArray(errorData)) {
              // If error data is an object, iterate over its keys
              Object.keys(errorData).forEach((key) => {
                const messages = errorData[key];
                if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                    toast.error(key + ": " + message);
                  });
                } else {
                  toast.error(key + ": " + messages);
                }
              });
            } else if (typeof errorData.message === "string") {
              // If there's a 'message' field, display it directly
              toast.error(errorData.message);
            } else {
              // Handle other types of error data as needed
              toast.error("Unexpected error occurred");
            }
          } else {
            // If there's no response from the server
            toast.error("Check your internet connection");
          }
          setLoading(false);
        });
      formData.append("file", selectedFile);
    }
  };

  // close modal
  const handleClose = () => {
    setOpen(false);
  };

  // hide and show password
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  return (
    <GeneralModal
      maxWidth="md"
      open={open}
      loading={loading}
      onSubmit={handleSubmit}
    >
      <Box sx={{ mx: 2, my: 2 }}>
        <Typography sx={{ mx: 2 }} variant="h5" fontSize={18} fontWeight={500}>
          User Information
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box noValidate>
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  defaultValue={dataItem?.username}
                  disabled={methodRequest === "edit" ? true : false}
                />
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="first_name"
                  label="First Name"
                  type="text"
                  id="first_name"
                  defaultValue={dataItem?.first_name}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
                noValidate
              >
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  defaultValue={dataItem?.email}
                />
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="last_name"
                  label="Last Name"
                  type="text"
                  id="last_name"
                  defaultValue={dataItem?.last_name}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
                noValidate
              >
                <TextField
                  type="date"
                  size="small"
                  margin="normal"
                  fullWidth
                  id="birthday"
                  name="birthday"
                  defaultValue={dataItem?.birth_date}
                />
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="phone"
                  label="Phone"
                  type="text"
                  id="phone"
                  defaultValue={dataItem?.phone}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
                noValidate
              >
                <SelectGender />
                <SelectWholeSale />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
                noValidate
              >
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="country"
                  label="Country"
                  type="text"
                  id="country"
                  defaultValue={"Iraq"}
                  disabled
                />
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  name="wallet_balance"
                  label="Wallet Balance"
                  type="number"
                  inputMode="numeric"
                  id="wallet_balance"
                  onWheel={(e) => e.target.blur()}
                  defaultValue={dataItem?.wallet_balance || 0}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
                noValidate
              >
                <SelectCity />
                <FormControlLabel
                  sx={{
                    mt: 2,
                  }}
                  control={
                    <Checkbox onChange={(e) => setIsActive(e.target.checked)} />
                  }
                  checked={isActive}
                  label="Active User"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
                noValidate
              >
                <FormControlLabel
                  sx={{
                    mt: 2,
                  }}
                  control={
                    <Checkbox onChange={(e) => setIsAdmin(e.target.checked)} />
                  }
                  checked={isAdmin}
                  label="Admin User"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </GeneralModal>
  );
}
