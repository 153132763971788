import React, { useEffect, useState } from "react";
import Slider from "../components/Slidbar/Slidbar";
import { useRecoilState } from "recoil";
import { openModalCompleteInfo, userInfo } from "../recoil";
import { Box, Drawer } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { openSlidbarState } from "../recoil/global";
import RouterApp from "../Router";


const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
export const Layout = () => {
  const [open, setOpen] = useRecoilState(openSlidbarState);
  const [userInfoData, setUserInfoData] = useRecoilState(userInfo);

  return (
    <Box>
      <Box sx={{ display: "flex", position: "relative" }}>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <DrawerHeader sx={{ display: userInfoData ? "flex" : "none" }} />
          
          <Box sx={{ ml: open ? 31 : 9 }}>
            <RouterApp />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
