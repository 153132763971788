import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { mockBarData } from "../../../data/mockData";

const BarChart = ({ isDashboard = false, data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [formatedData, setFormatedData] = useState([]);
  const [keys, setKeys] = useState([]);

  const getKeys = (data) => {
    if (data.length > 0) {
      const keys = data.map((item) => {
        return item.values.map(([key, value]) => key);
      });

      console.log(keys.flat());

      // Convert array to Set to remove duplicates
      let uniqueArray = [...new Set( keys.flat().map((key) => {
        return String(key);
      }))];
      setKeys(uniqueArray);
          console.log(uniqueArray,"uniqueArray");
    }
  };
  useEffect(() => {
    getKeys(data);
  }, [data]);

  const transformData = (data) => {
    if (data.length > 0) {
      const transformed = data.map((item) => {
        const newObj = { label: item.label };
        item.values.forEach(([key, value]) => {
          newObj[key] = value;
        });
        if (item.value !== null) {
          newObj.value = item.value;
        }
        return newObj;
      });
      console.log(transformed);
      return transformed;
    }
  };

  useEffect(() => {
    setFormatedData(transformData(data));
  }, [data]);

  return formatedData && formatedData.length > 0 ? (
    <ResponsiveBar
      data={formatedData}
      keys={keys}
      indexBy="label"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "value",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  ) : (
    <div>No Data</div>
  );
};

export default BarChart;
