import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { MdOutlineRemoveDone } from "react-icons/md";
import { TfiBackLeft } from "react-icons/tfi";
import { MdOutlineAttachMoney } from "react-icons/md";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { FaEye } from "react-icons/fa";
import { FaRegCopy } from "react-icons/fa";

export const ColumnButton = (props) => {
  return (
    <Box>
      {props.type === "edit" ? (
        <Tooltip title="Edit">
          <IconButton color="warning" onClick={props?.onClick}>
            <AiOutlineEdit />
          </IconButton>
        </Tooltip>
      ) : props.type === "delete" ? (
        <Tooltip title="Delete">
          <IconButton color="error" onClick={props?.onClick}>
            <AiOutlineDelete />
          </IconButton>
        </Tooltip>
      ) : props.type === "loading" ? (
        <Tooltip title="Loading">
          <IconButton color="warning" onClick={props?.onClick}>
            <HourglassBottomIcon />
          </IconButton>
        </Tooltip>
      ) : props.type === "approve" ? (
        <Tooltip title="Approve">
          <IconButton color="success" onClick={props?.onClick}>
            <IoCheckmarkDoneSharp />
          </IconButton>
        </Tooltip>
      ) : props.type === "reject" ? (
        <Tooltip title="Reject">
          <IconButton sx={{ color: "#e84118" }} onClick={props?.onClick}>
            <MdOutlineRemoveDone />
          </IconButton>
        </Tooltip>
      ) : props.type === "return" ? (
        <Tooltip title="Return">
          <IconButton sx={{ color: "#e1b12c" }} onClick={props?.onClick}>
            <TfiBackLeft />
          </IconButton>
        </Tooltip>
      ) : props.type === "paid" ? (
        <Tooltip title="Make as Paid">
          <IconButton sx={{ color: "#0097e6" }} onClick={props?.onClick}>
            <MdOutlineAttachMoney />
          </IconButton>
        </Tooltip>
      ) : props.type === "show" ? (
        <Tooltip title="Show">
          <IconButton sx={{ color: "#6c5ce7" }} onClick={props?.onClick}>
            <FaEye />
          </IconButton>
        </Tooltip>
      ) : props.type === "copy" ? (
        <Tooltip title="Copy">
          <IconButton sx={{ color: "#6c5ce7" }} onClick={props?.onClick}>
            <FaRegCopy />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={props.title}>
          <IconButton color={props.color} onClick={props?.onClick}>
            {props.icon}
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
