import React, { useEffect, useState } from "react";
import {
  FormControl,
  TextField,
  Autocomplete,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { dataClickItem } from "../../recoil/global";
import { selectedCityState } from "../../recoil/select";

const SelectCity = (props) => {
  const [selectedCity, setSelectedCity] = useRecoilState(
    selectedCityState
  );
  const dataItem = useRecoilState(dataClickItem);
  const [data, setData] = useState([]);

  const handleChange = (event) => {
    setSelectedCity(event.target.value);
  };


  useEffect(() => {
    // get data cities
    setData([
      "al_anbar",
      "al_basra",
      "al_muthanna",
      "an_najaf",
      "al_qadisiyah",
      "al_sulaymaniyah",
      "babil",
      "baghdad",
      "dhi_qar",
      "dyala",
      "dohuk",
      "erbil",
      "karbala",
      "kirkuk",
      "maysan",
      "ninawa",
      "salah_ad_din",
      "wasit",
    ]);
  }, []);

  const transformStatus = (status) => {
    return status
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <FormControl fullWidth margin="normal" sx={{ minWidth: 300 }}>
      <InputLabel id="status-label" sx={{ background: "#fff", top: -6 }}>
        Select City
      </InputLabel>
      <Select
        size="small"
        sx={{ minWidth: 300 }}
        labelId="status-label"
        id="status-select"
        onChange={handleChange}
        value={selectedCity}
      >
        {data?.map((status, index) => (
          <MenuItem key={index} value={status}>
            {transformStatus(status)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectCity;
