import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { useRecoilState } from "recoil";
import { openFilterState } from "../../recoil/global";
import { TextField, Typography } from "@mui/material";
import SelectStatus from "../Selects/SelectStatus";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { selectedTransactionTypeState } from "../../recoil/select";
import SelectTransactionType from "../Selects/SelectTransactionType";

export default function Filter(props) {
  const [state, setState] = useRecoilState(openFilterState);

  const [selectedTransactionType, setSelectedTransactionType] = useRecoilState(
    selectedTransactionTypeState
  );

  const handleSearch = () => {
    if (props.emailSearch || props.phoneSearch || selectedTransactionType) {
      props.fetchData();
    }
  };

  const handleRemoveSearch = () => {
    props.setEmailSearch(null);
    props.setPhoneSearch(null);

    setSelectedTransactionType(null);

    props.fetchData();
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
        mt: 8,
      }}
      role="presentation"
    >
      <Box sx={{ m: 1, position: "relative", height: "86vh" }}>
        <Typography mt={2} variant="h6">
          Filter Advancis
        </Typography>
        <TextField
          size="small"
          margin="normal"
          fullWidth
          id="userNames"
          label="User Name"
          name="userNames"
          autoComplete="userNames"
          value={props.userNames ? props.userNames : ""}
          onChange={(e) => props.setUserNames(e.target.value)}
        />
        <TextField
          size="small"
          margin="normal"
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          value={props.emailSearch ? props.emailSearch : ""}
          onChange={(e) => props.setEmailSearch(e.target.value)}
          autoFocus
        />
        {/* <TextField
          size="small"
          margin="normal"
          fullWidth
          id="phone"
          label="Phone Number"
          name="phone"
          autoComplete="phone"
          value={props.phoneSearch ? props.phoneSearch : ""}
          onChange={(e) => props.setPhoneSearch(e.target.value)}
        /> */}

        <TextField
          size="small"
          margin="normal"
          fullWidth
          id="firstName"
          label="First Name"
          name="firstName"
          autoComplete="firstName"
          value={props.firstName ? props.firstName : ""}
          onChange={(e) => props.setFirstName(e.target.value)}
        />
        <TextField
          size="small"
          margin="normal"
          fullWidth
          id="lastName"
          label="Last Name"
          name="lastName"
          autoComplete="lastName"
          value={props.lastName ? props.lastName : ""}
          onChange={(e) => props.setLastName(e.target.value)}
        />
        <TextField
          size="small"
          margin="normal"
          fullWidth
          id="orderNumber"
          label="Order Number"
          name="orderNumber"
          autoComplete="orderNumber"
          value={props.orderNumber ? props.orderNumber : ""}
          onChange={(e) => props.setOrderNumber(e.target.value)}
        />
        <TextField
          size="small"
          margin="normal"
          fullWidth
          id="createdAfter"
          label="Created After"
          name="createdAfter"
          autoComplete="createdAfter"
          type="date"
          value={props.createdAfter ? props.createdAfter : new Date()}
          onChange={(e) => props.setCreatedAfter(e.target.value)}
        />
        <TextField
          size="small"
          margin="normal"
          fullWidth
          id="createdBefore"
          label="Created Before"
          name="createdBefore"
          autoComplete="createdBefore"
          type="date"
          value={props.createdBefore ? props.createdBefore : new Date()}
          onChange={(e) => props.setCreatedBefore(e.target.value)}
        />
        <SelectTransactionType />
        <Box sx={{ position: "absolute", bottom: 0, right: 0, left: 0 }}>
          <Button
            onClick={() => handleSearch()}
            sx={{ width: "100%" }}
            variant="outlined"
            startIcon={<SearchIcon />}
          >
            Search
          </Button>
          <Button
            onClick={() => handleRemoveSearch()}
            color="error"
            sx={{ width: "100%", mt: 1 }}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Remove
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"right"} className="mt-5 pt-5">
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
