import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import {
  openModalApplicant,
  openModalDelete,
  openModalInfo,
  openModalShow,
} from "../../recoil/modal";
import { methodRequestStatus } from "../../recoil/global";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DrawIcon from "@mui/icons-material/Draw";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function GeneralModal(props) {
  const setOpenInfo = useSetRecoilState(openModalInfo);
  const setOpenShow = useSetRecoilState(openModalShow);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setOpenApplicant = useSetRecoilState(openModalApplicant);
  const methodRequest = useRecoilValue(methodRequestStatus);

  const onClose = () => {
    try {
      props.onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpenShow(false);
    setOpenInfo(false);
    setOpenDelete(false);
    setOpenApplicant(false);
    onClose();
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        maxWidth={props.maxWidth ? props.maxWidth : "lg"}
        aria-labelledby="draggable-dialog-title"
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: 10,
        }}
      >
        <DialogTitle
          className="color-primary"
          id="draggable-dialog-title"
          style={{
            cursor: "move",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #bdc3c7",
          }}
        >
          <Typography variant="h6" fontWeight={600}>
            {methodRequest === "add" ? (
              <AddCircleIcon className="color-primary" />
            ) : methodRequest === "edit" ? (
              <DrawIcon className="color-primary" />
            ) : methodRequest === "delete" ? (
              <DeleteIcon className="color-primary" />
            ) : (
              props.icon
            )}
            &nbsp;
            {methodRequest === "add"
              ? "Add"
              : methodRequest === "edit"
              ? "Edit"
              : methodRequest === "delete"
              ? "Delete"
              : props.title}
          </Typography>
          {methodRequest == "remove-footer" && (
            <Tooltip title="Close">
              <IconButton color="warning" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )}
        </DialogTitle>
        <Box component="form" onSubmit={props.onSubmit}>
          <DialogContent sx={{ overflow: "hidden" }}>
            <DialogContentText>{props.children}</DialogContentText>
          </DialogContent>
          {methodRequest !== "remove-footer" && (
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
              {!props?.btnFooterHide && !props?.loading && (
                <Button
                  disabled={props.disabled}
                  variant="contained"
                  type="submit"
                >
                  {methodRequest === "add"
                    ? "Add"
                    : methodRequest === "edit"
                    ? "Save"
                    : methodRequest === "delete"
                    ? "Delete"
                    : "Done"}
                </Button>
              )}
              {!props.btnFooterHide && props?.loading && (
                <Button
                  disabled={props.disabled}
                  variant="contained"
                  type="submit"
                
                >
                  Loading...
                </Button>
              )}
            </DialogActions>
          )}
        </Box>
      </Dialog>
    </div>
  );
}
