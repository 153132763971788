import { deleteData, getData, postData, putData } from "..";

// get Transactions
export const GetTransactionsData = async (
  skip,
  take,
  search,
  emailSearch,
  phoneSearch,
  transactionType,
  userNames,
  firstName,
  lastName,
  orderNumber,
  createdAfter,
  createdBefore
) => {
  if ((skip !== undefined && take !== undefined) || search !== search) {
    try {
      const request = await getData(
        `/auth/admin/transactions/?page=${
          skip === 0 ? 1 : skip || take
        }&page_size=${take}&search=${search}${
          emailSearch ? `&email=${emailSearch}` : ""
        }${phoneSearch ? `&phone=${phoneSearch}` : ""}${
          transactionType ? `&transaction_type=${transactionType}` : ""
        }${userNames ? `&user__username=${userNames}` : ""}${
          firstName ? `&first__name=${firstName}` : ""
        }${lastName ? `&last__name=${lastName}` : ""}${
          orderNumber ? `&related_order__order_number=${orderNumber}` : ""
        }${createdAfter ? `&created_after=${createdAfter}` : ""}${
          createdBefore ? `&created_before=${createdBefore}` : ""
        }&ordering=-created`
      ); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// post Transactions
export const PostTransactions = async (data) => {
  try {
    const request = await postData(`/auth/admin/transactions/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};
