import { deleteData, getData, postData, putData } from "..";

// get keyValidity
export const GetKeyValidityData = async (skip, take, search) => {
    if (skip !== undefined && take !== undefined || search !== search) {
        try {
            const request = await getData(`/key_validity?page=${skip === 0 ? 1 : skip || take}&page_size=${take}&search=${search}`); // Handle the data
            return request;
        } catch (error) {
            console.error(error); // Handle the error
            throw error;
        }
    }
};

// get keyValidity By Id
export const GetKeyValidityByIdData = async (id) => {
    if (id) {
        try {
            const request = await getData(`/admin/key_validity/${id}`); // Handle the data
            return request;
        } catch (error) {
            console.error(error); // Handle the error
            throw error;
        }
    }
};

// post keyValidity
export const PostKeyValidity = async (data) => {
    try {
        const request = await postData(`/key_validity/`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

// put keyValidity
export const PutKeyValidity = async (id, data) => {
    try {
        const request = await putData(`/key_validity/${id}/`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

// delete keyValidity
export const DeleteKeyValidity = async (id) => {
    try {
        const request = await deleteData(`/key_validity/${id}`); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

