import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { Checkbox, FormControlLabel } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

const MultiText = ({ keyList, setKeyList }) => {
  const handleChange = (id, value) => {
    const list = keyList.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          key: value,
          state: item?.state === "new" ? "new" : "updated",
        };
      }
      return item;
    });
    setKeyList(list);
  };

  const handleDelete = (id) => {
    const list = keyList.map((item) => {
      if (item.id === id && item?.state === "new") {
        return { ...item, state: "null" };
      } else if (item.id === id) {
        return { ...item, state: "deleted" };
      }
      return item;
    });
    setKeyList(list);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (!file) {
      toast.error("No file selected!");
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0]; // Assuming the first sheet is the one needed
        const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: 1,
        });

        if (sheet.length === 0 || sheet[0][0] !== "keys") {
          throw new Error('First element in the table must be "keys"');
        }

        const parsedData = sheet.slice(1).map((row) => ({
          id: uuidv4(),
          state: "new",
          key: row[0], // Assuming "keys" is the first column
          // Add other columns here as needed
        }));

        setKeyList(parsedData);
        toast.success("File uploaded and processed successfully!");
      } catch (error) {
        toast.error(`Error processing file: ${error.message}`);
      }
    };

    reader.onerror = () => {
      toast.error("Error reading file!");
    };

    reader.readAsBinaryString(file);
  };

  return (
    <Box
      sx={{
        mt: 3,
      }}
    >
      <Grid container>
        {keyList
          .filter((item) => item.state !== "deleted" && item.state !== "null")
          .map((item, index) => (
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
              item
              xs={12}
              sm={12}
              key={item.id}
            >
              <Grid sm={4} xs={12}>
                <Box
                  sx={{
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 2,
                  }}
                  noValidate
                >
                  <TextField
                    fullWidth
                    margin="small"
                    size="small"
                    id="outlined-basic"
                    label="Key"
                    value={item.key}
                    onChange={(e) => {
                      handleChange(item.id, e.target.value);
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                sx={{
                  mb: 1,
                }}
                sm={8}
                xs={12}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    mt: 3,
                  }}
                  noValidate
                >
                  <Button
                    margin="normal"
                    component="label"
                    variant="outlined"
                    sx={{
                      width: "90%",
                    }}
                    onClick={() => {
                      handleDelete(item.id);
                    }}
                  >
                    Delete
                  </Button>
                  <FormControlLabel
                    sx={{
                      mx: 2,
                    }}
                    control={
                      <Checkbox
                        id="used"
                        disabled={true}
                        checked={item.is_used} // Bind the state to the checkbox
                      />
                    }
                    label="Used"
                  />
                  {item.is_used && (
                    <TextField
                      fullWidth
                      margin="small"
                      size="small"
                      id="outlined-basic"
                      label="Used By"
                      value={`${item.used_by} - ${item.used_order}`}
                    />
                  )}
                  <FormControlLabel
                    sx={{
                      mx: 2,
                    }}
                    control={
                      <Checkbox
                        id="viewed"
                        disabled={true}
                        checked={item.is_viewed} // Bind the state to the checkbox
                      />
                    }
                    label="Viewed"
                  />
                </Box>
              </Grid>
            </Grid>
          ))}
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "90%",
          mx: "auto",
          gap: 2,
          mt: 3,
        }}
        noValidate
      >
        <Button
          margin="normal"
          component="label"
          variant="outlined"
          sx={{
            width: "90%",
            my: 2,
          }}
          onClick={() => {
            setKeyList([
              ...keyList,
              {
                id: uuidv4(),
                key: "",
                is_used: false,
                used_by: "",
                used_order: "",
                is_viewed: false,
                state: "new",
              },
            ]);
          }}
        >
          New Key
        </Button>
        <Button
          margin="normal"
          component="label"
          variant="outlined"
          startIcon={<CloudUploadIcon />}
          sx={{
            width: "90%",
            my: 2,
          }}
        >
          Upload Exel file
          <input
            type="file"
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default MultiText;
