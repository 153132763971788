import React, { useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useRecoilState } from "recoil";
import { selectedNotificationLevelState } from "../../recoil/select";

const SelectLevel = (props) => {
  const [selectedNotification, setSelectedNotification] = useRecoilState(
    selectedNotificationLevelState
  );

  const handleChange = (event) => {
    setSelectedNotification(event.target.value);
  };

  const data = [
    {
      level: "low",
      label: "Low",
    },
    {
      level: "normal",
      label: "Normal",
    },
    {
      level: "important",
      label: "Important",
    },
    {
      level: false,
      label: "All",
    },
  ];

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <FormControl fullWidth margin="normal" sx={{ minWidth: 300 }}>
      <InputLabel id="status-label" sx={{ background: "#fff", top: -6 }}>
        Select Level
      </InputLabel>
      <Select
        size="small"
        sx={{ minWidth: 300 }}
        labelId="status-label"
        id="status-select"
        onChange={handleChange}
        value={selectedNotification}
      >
        {data?.map((status, index) => (
          <MenuItem key={index} value={status.level}>
            {capitalizeFirstLetter(status.label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectLevel;
