import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { useRecoilState } from "recoil";
import { openFilterState } from "../../recoil/global";
import { FormControlLabel, TextField, Typography } from "@mui/material";
import SelectStatus from "../Selects/SelectStatus";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { selectedTagsState } from "../../recoil/select";
import SelecteTags from "../Selects/SelectTags";
import { selectedSubCategoryState } from "../../recoil/select";
import { selectedCategoryState } from "../../recoil/select";
import SelectCategory from "../Selects/SelectCategory";
import SelectSubCategory from "../Selects/SelectSubCategory";
import Checkbox from "@mui/material/Checkbox";

import { useState } from "react";
import { Form } from "react-router-dom";

export default function Filter(props) {
  const [state, setState] = useRecoilState(openFilterState);
  const [selectedTags, setSelectedTags] = useRecoilState(selectedTagsState);
  const [selectedCategory, setSelectedCategory] = useRecoilState(
    selectedCategoryState
  );
  const [selectedSubCategory, setSelectedSubCategory] = useRecoilState(
    selectedSubCategoryState
  );

  const handleSearch = () => {
    if (
      props.disconted ||
      props.isOpthions ||
      props.hasOpthins ||
      selectedTags ||
      selectedCategory ||
      selectedSubCategory
    ) {
      props.fetchData();
    }
  };

  const handleRemoveSearch = () => {
    props.setDisconted(null);
    props.setIsOptions(null);
    props.setHasOptions(null);
    setSelectedTags(null);
    setSelectedCategory(null);
    setSelectedSubCategory(null);

    props.fetchData();
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 350,
        mt: 8,
      }}
      role="presentation"
    >
      <Box sx={{ m: 1, position: "relative", height: "86vh" }}>
        <Typography mt={2} variant="h6">
          Filter Advancis
        </Typography>

        <SelecteTags />
        <SelectCategory />
        <SelectSubCategory />

        <FormControlLabel
          sx={{
            mt: 2,
          }}
          control={
            <Checkbox onChange={(e) => props.setDisconted(e.target.checked)} />
          }
          label="Disconted"
        />
        <FormControlLabel
          sx={{
            mt: 2,
          }}
          control={
            <Checkbox onChange={(e) => props.setHasOptions(e.target.checked)} />
          }
          label="Has Opthions"
        />
        <FormControlLabel
          sx={{
            mt: 2,
          }}
          control={
            <Checkbox onChange={(e) => props.setIsOptions(e.target.checked)} />
          }
          label="Is Opthions"
        />

        <Box sx={{ position: "absolute", bottom: 0, right: 0, left: 0 }}>
          <Button
            onClick={() => handleSearch()}
            sx={{ width: "100%" }}
            variant="outlined"
            startIcon={<SearchIcon />}
          >
            Search
          </Button>
          <Button
            onClick={() => handleRemoveSearch()}
            color="error"
            sx={{ width: "100%", mt: 1 }}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Remove
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"right"} className="mt-5 pt-5">
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
