import { atom } from "recoil";


// open modal change password 
export const openModalChangePassword = atom({
  key: 'openModalChangePassword',
  default: null,
});

// open modal on click button edit job title
export const openModalEditJobTitle = atom({
  key: 'openModalEditJobTitle',
  default: false,
});

// open modal add
export const openModalAdd = atom({
  key: 'openModalAdd',
  default: false,
});

// open modal info
export const openModalInfo = atom({
  key: 'openModalInfo',
  default: false,
});

// open modal info
export const openModalShow = atom({
  key: 'openModalShow',
  default: false,
});

// open modal delete
export const openModalDelete = atom({
  key: 'openModalDelete',
  default: false,
});

// open modal delete job title
export const openModalDeleteJobTitle = atom({
  key: 'openModalDeleteJobTitle',
  default: false,
});

// open modal on click button applicant
export const openModalApplicant = atom({
  key: 'openModalApplicant',
  default: false,
});

// open modal on click button profile Seeket State
export const openModalProfileSeeketState = atom({
  key: 'openModalProfileSeeketState',
  default: false,
});

// open modal on click button confierm State
export const confirmModalState = atom({
  key: 'confirmModalState',
  default: false,
});