import React from 'react';
import { Button } from "@mui/material";
import * as XLSX from 'xlsx';
import { SiGoogledocs } from "react-icons/si";

const ExportButton = ({ data, fileName }) => {
    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    };

    return (
        <Button
            variant="contained"
            startIcon={<SiGoogledocs size={25} />}
            sx={{
                backgroundColor: "#27ae60",
                "&:hover": {
                    backgroundColor: "#27ae60",
                },
                mx:1
            }}
            onClick={() => exportToExcel()}
        >
            Export to Excel
        </Button>
    );
};

export default ExportButton;
