import { Box, Button, Typography, useTheme, ButtonGroup } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import TrafficIcon from "@mui/icons-material/Traffic";
import LineChart from "./Chart/LineChart";
import BarChart from "./Chart/BarChart";
import StatBox from "./Chart/StatBox";
import ProgressCircle from "./Chart/ProgressCircle";
import { useEffect } from "react";
import { useState } from "react";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import WorkIcon from "@mui/icons-material/Work";
import { GetReportsApi } from "../../services/api/home";
import { GetUserData } from "../../services/api/user";
import { GetOrderDataHome } from "../../services/api/order";
import { GetWholeSaleData } from "../../services/api/wholeSale";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState(null);
  const [dataLine, setDataLine] = useState([]);
  const [duration, setDuration] = useState("yearly");
  const [barChartData, setBarChartData] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [wholeSaleTypes, setWholeSaleTypes] = useState([]);
  const [userCountByHoleSale, setUserCountByHoleSale] = useState([]);
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingWholeSale, setLoadingWholeSale] = useState(false);

  const fetchBarChartData = async () => {
    try {
      const response = await GetReportsApi();
      //   console.log(response);
      setBarChartData(response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUsersData = () => {
    setLoadingUser(true);
    GetUserData(0, 10000, "")
      .then((res) => {
        console.log("res?.data?.count", res);
        setUserCount(res?.count);
        setAllUsers(res?.results);
        setLoadingUser(false);
      })
      .catch(() => {
        console.log("error");
        setLoadingUser(false);
      });
  };

  const fetcWholeSaleData = () => {
    setLoadingWholeSale(true);
    GetWholeSaleData(0, 10000, "")
      .then((res) => {
        console.log("res?.data?.count", res);
        setWholeSaleTypes(res?.results);
        setLoadingWholeSale(false);
      })
      .catch(() => {
        console.log("error");
        setLoadingWholeSale(false);
      });
  };

  const fetchOrderData = () => {
    GetOrderDataHome(0, 2, "")
      .then((res) => {
        console.log("res?.data?.count", res);
        setTotalOrders(res?.count);
      })
      .catch(() => {
        console.log("error");
      });
  };

  useEffect(() => {
    // get data cities
    fetchBarChartData();
    fetchUsersData();
    fetchOrderData();
    fetcWholeSaleData();
  }, []);

  useEffect(() => {
    if (!loadingUser && !loadingWholeSale) {
      // how many users are there in each wholesaler
      const userCountByHoleSale = [];
      wholeSaleTypes.forEach((wholeSale) => {
        const count = allUsers.filter(
          (user) => user.wholesale_type === wholeSale.id
        ).length;
        userCountByHoleSale.push({
          id: wholeSale.id,
          title: wholeSale.title,
          value: count,
        });
      });
      setUserCountByHoleSale(userCountByHoleSale);
    }
  }, [loadingUser, loadingWholeSale]);

  useEffect(() => {
    console.log("userCountByHoleSale", userCountByHoleSale);
  }, [userCountByHoleSale]);

  useEffect(() => {
    const durationLineData = [
      {
        id: "Employers",
        color: tokens("dark").greenAccent[500],
        data: [],
      },
      {
        id: "Seekers",
        color: tokens("dark").blueAccent[300],
        data: [],
      },
      {
        id: "Jobs",
        color: tokens("dark").redAccent[300],
        data: [],
      },
    ];
    setDataLine(durationLineData);
  }, [duration]);

  return (
    <Box m="20px">
      {/* GRID & CHARTS */}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor="#ffffff"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius={3}
            border="1px solid #d4d4d4"
            boxShadow="1px 4px 10px rgba(0,0,0,.1)"
            sx={{
              transition: ".3s all ease-in-out",
              ":hover": {
                transform: "scale(1.025)",
              },
            }}
          >
            <StatBox
              title={String(userCount)}
              subtitle="Total Users"
              progress=".5"
              increase=""
              icon={
                <SupervisedUserCircleIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor="#ffffff"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius={3}
            border="1px solid #d4d4d4"
            boxShadow="1px 4px 10px rgba(0,0,0,.1)"
            sx={{
              transition: ".3s all ease-in-out",
              ":hover": {
                transform: "scale(1.025)",
              },
            }}
          >
            <StatBox
              title={String(totalOrders)}
              subtitle="Total Orders"
              progress=".5"
              increase=""
              icon={
                <AssignmentIndIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          {/* <Box
            width="100%"
            backgroundColor="#ffffff"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius={3}
            border="1px solid #d4d4d4"
            boxShadow="1px 4px 10px rgba(0,0,0,.1)"
            sx={{
              transition: ".3s all ease-in-out",
              ":hover": {
                transform: "scale(1.025)",
              },
            }}
          >
            <StatBox
              title={data?.total_jobs}
              subtitle="Total Jobs"
              progress=".5"
              increase=""
              icon={
                <WorkIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box> */}
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          {/* <Box
            width="100%"
            backgroundColor="#ffffff"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius={3}
            border="1px solid #d4d4d4"
            boxShadow="1px 4px 10px rgba(0,0,0,.1)"
            sx={{
              transition: ".3s all ease-in-out",
              ":hover": {
                transform: "scale(1.025)",
              },
            }}
          >
            <StatBox
              title={data?.jobs_this_month}
              subtitle="Jobs this Month"
              progress=".5"
              increase=""
              icon={
                <TrafficIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box> */}
        </Grid>

        {/* <Grid
          xs={12}
          sm={12}
          md={8}
          lg={12}
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid xs={12} lg={8}>
            <Box
              mt={6}
              backgroundColor="#ffffff"
              alignItems="center"
              justifyContent="center"
              borderRadius={3}
              border="1px solid #d4d4d4"
              boxShadow="1px 4px 10px rgba(0,0,0,.1)"
              sx={{
                transition: ".3s all ease-in-out",
                ":hover": {
                  transform: "scale(1.025)",
                },
              }}
            >
              <Box
                my="25px"
                p="0 30px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color={colors.grey[100]}
                  >
                    Duration Statistics
                  </Typography>
                </Box>
                <Box>
                  <ButtonGroup size="small" aria-label="outlined button group">
                    <Button
                      onClick={() => setDuration("weekly")}
                      variant={duration === "weekly" ? "contained" : "outlined"}
                    >
                      Weekly
                    </Button>
                    <Button
                      onClick={() => setDuration("monthly")}
                      variant={
                        duration === "monthly" ? "contained" : "outlined"
                      }
                    >
                      Monthly
                    </Button>
                    <Button
                      onClick={() => setDuration("yearly")}
                      variant={duration === "yearly" ? "contained" : "outlined"}
                    >
                      Yearly
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
              <Box height="258px" m="-20px 0 0 0">
                <LineChart lineData={dataLine} isDashboard={true} />
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box
              mt={6}
              backgroundColor="#ffffff"
              alignItems="center"
              borderRadius={3}
              border="1px solid #d4d4d4"
              boxShadow="1px 4px 10px rgba(0,0,0,.1)"
              p="33px"
              sx={{
                transition: ".3s all ease-in-out",
                ":hover": {
                  transform: "scale(1.025)",
                },
              }}
            >
              <PieChart />
            </Box>
          </Grid> 
         </Grid> */}
        <Grid xs={12} sm={12} md={12} lg={12}>
          <Box
            mt={6}
            backgroundColor="#ffffff"
            alignItems="center"
            borderRadius={3}
            border="1px solid #d4d4d4"
            boxShadow="1px 4px 10px rgba(0,0,0,.1)"
            sx={{
              transition: ".3s all ease-in-out",
              ":hover": {
                transform: "scale(1.025)",
              },
            }}
          >
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ padding: "30px 30px 0 30px" }}
            >
              Sales Quantity
            </Typography>
            <Box height="350px" mt="-20px">
              <BarChart isDashboard={true} data={barChartData} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
