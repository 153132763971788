import { atom } from "recoil";

// save user info 
export const userInfo = atom({
    key: 'userInfo',
    default: null,
});

// open Modal Complete Info 
export const openModalCompleteInfo = atom({
    key: 'openModalCompleteInfo',
    default: null,
});


// update data jop field
export const updateDataJobTitle = atom({
    key: 'updateDataJobTitle',
    default: null,
});


export const notificationsState = atom({
    key: 'notificationsState',
    default: [],
});
