import React, { useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useRecoilState } from "recoil";
import { selectedTransactionTypeState } from "../../recoil/select";

const SelectTransactionType = (props) => {
  const [selectedTransactionType, setSelectedTransactionType] = useRecoilState(selectedTransactionTypeState);

  const handleChange = (event) => {
    setSelectedTransactionType(event.target.value);
  };

  const data = ["deposit", "order"];

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <FormControl fullWidth margin="normal" sx={{ minWidth: 300 }}>
      <InputLabel id="status-label" sx={{ background: "#fff", top: -6 }}>
        Select Transaction Type
      </InputLabel>
      <Select
        size="small"
        sx={{ minWidth: 300 }}
        labelId="status-label"
        id="status-select"
        onChange={handleChange}
        value={selectedTransactionType}
      >
        {data?.map((status, index) => (
          <MenuItem key={index} value={status}>
            {capitalizeFirstLetter(status)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectTransactionType;
