import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useRecoilState } from 'recoil';
import { selectedPaymentStatus } from '../../recoil/select';

const SelectPaymentStatus = (props) => {
    const [paymentStatus, setPaymentStatus] = useRecoilState(selectedPaymentStatus);

    const handleChange = (event) => {
        setPaymentStatus(event.target.value);
    };

    const data = ['', 'true', 'false']

    return (
        <FormControl fullWidth margin="normal" sx={{ minWidth: 300 }}>
            <InputLabel id="isView-label" sx={{ background: '#fff' }}>
                Select Payment Status
            </InputLabel>
            <Select
                size='small'
                sx={{ minWidth: 300 }}
                labelId="item-label"
                id="item-select"
                onChange={handleChange}
                value={paymentStatus}
            >
                {data?.map((item, index) => (
                    <MenuItem key={index} value={item}>
                        {item}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectPaymentStatus;
