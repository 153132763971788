import React from "react";
import Slider from "../components/Slidbar/Slidbar";
import Tabel from "../components/WholeSale/Tabel";

export const WholeSale = () => {
  return (
    <>
      <Slider />
      <Tabel />
    </>
  );
};
