import { useEffect } from "react";
import { Avatar, Box } from "@mui/material";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
  updateDataState,
} from "../../recoil/global";
import {
  confirmModalState,
  openModalDelete,
  openModalInfo,
  openModalShow,
} from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { useState } from "react";
import { dataTabelState } from "../../recoil/Tabel";
import Filters from "./Filters";
import {
  selectedCreatedFromDateState,
  selectedCreatedToDateState,
  selectedIsViewState,
  selectedPaymentMethodState,
  selectedPaymentStatus,
  selectedStatusState,
} from "../../recoil/select";
import { GetOrderData } from "../../services/api/order";
import icon_master from "../../assets/images/icons/master.png";
import icon_zainCash from "../../assets/images/icons/zain_cash.png";
import icon_fastPay from "../../assets/images/icons/fast_pay.png";
import icon_fib from "../../assets/images/icons/fib.png";
import icon_cash from "../../assets/images/icons/cash.png";
import ModalShow from "./Modal/ModalShow";

export const Tabel = () => {
  const [data, setData] = useRecoilState(dataTabelState);
  const [emailSearch, setEmailSearch] = useState(null);
  const [phoneSearch, setPhoneSearch] = useState(null);

  const selectedStatus = useRecoilValue(selectedStatusState);
  const state = useRecoilValue(openFilterState);
  const updateData = useRecoilValue(updateDataState);
  const selectedPaymentMethod = useRecoilValue(selectedPaymentMethodState);
  const paymentStatus = useRecoilValue(selectedPaymentStatus);
  const selectedCreatedFromDate = useRecoilValue(selectedCreatedFromDateState);
  const selectedCreatedToDate = useRecoilValue(selectedCreatedToDateState);
  const isView = useRecoilValue(selectedIsViewState);

  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenShowModal = useSetRecoilState(openModalShow);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);

  function formatDate(inputDate) {
    let formattedDate = `${inputDate.split("T")[0]}`;
    return formattedDate;
  }
  function formatHours(inputDate) {
    let date = new Date(inputDate);
    let hours = date.getHours();
    let minutes = String(date.getMinutes()).padStart(2, "0");
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    let formattedDate = `${hours}:${minutes} ${period}`;
    return formattedDate;
  }

  useEffect(() => {
    setDataItem(null);
  }, [state]);
  const handelShow = (data) => {
    setOpenShowModal(true);
    setDataItem(data);
    setMethodRequest("show");
  };
  const handelPaid = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("paid");
  };
  const handelApproved = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("approved");
  };
  const handelRejected = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("rejected");
  };
  const handelReturn = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("return");
  };
  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };

  const columns = [
    {
      field: "order_number",
      headerName: "Order Number",
      headerClassName: "grid--header",
      minWidth: 140,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={params.row?.order_number ? params.row?.order_number : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "grid--header",
      minWidth: 140,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={
                params.row?.created_by?.first_name
                  ? params.row?.created_by?.first_name
                  : "N/A" + " " + params.row?.created_by?.last_name
                  ? params.row?.created_by?.last_name
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "grid--header",
      minWidth: 220,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={params.row?.created_by?.email}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      headerClassName: "grid--header",
      minWidth: 140,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={params.row?.created_by?.phone}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "is_wholesale",
      headerName: "Is Wholesle",
      headerClassName: "grid--header",
      minWidth: 140,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              color={params.row?.is_wholesale ? "#27ae60" : "#e74c3c"}
              text={`${params.row?.is_wholesale ? "Yes" : "No"}`}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "use_wallet",
      headerName: "Use Wallet",
      headerClassName: "grid--header",
      minWidth: 140,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              color={params.row?.use_wallet ? "#27ae60" : "#e74c3c"}
              text={`${params.row?.use_wallet ? "Yes" : "No"}`}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "order_lines",
      headerName: "QTY",
      headerClassName: "grid--header",
      minWidth: 140,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={
                params.row?.order_lines
                  ? `${params.row?.order_lines?.length}`
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "total_price",
      headerName: "Total Price IQD",
      headerClassName: "grid--header",
      minWidth: 140,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={
                params.row?.total_price
                  ? (params.row?.total_price).toLocaleString()
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "total_price_usd",
      headerName: "Total Price USD",
      headerClassName: "grid--header",
      minWidth: 140,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={
                params.row?.total_price_usd
                  ? (params.row?.total_price_usd).toLocaleString()
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "payment_method",
      headerName: "Payment Method",
      headerClassName: "grid--header",
      minWidth: 120,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <Avatar
              sx={{
                borderRadius: 0,
              }}
              sizes="small"
              alt="Image"
              src={
                params.row?.payment_method === "cash"
                  ? icon_cash
                  : params.row?.payment_method === "zain_cash"
                  ? icon_zainCash
                  : params.row?.payment_method === "fast_pay"
                  ? icon_fastPay
                  : params.row?.payment_method === "credit_card"
                  ? icon_master
                  : params.row?.payment_method === "fib"
                  ? icon_fib
                  : ""
              }
            />
            {/* <ColumnText text={params.row?.payment_method ?
                            params.row?.payment_method === 'cash' ? 'Cash' :
                                params.row?.payment_method === 'zain_cash' ? 'Zain Cash' :
                                    params.row?.payment_method === 'fast_pay' ? 'Fast Pay' :
                                        params.row?.payment_method === 'credit_card' ? 'Credit Card' :
                                            params.row?.payment_method === 'fib ' ? 'FIB' : 'N/A'
                            : "N/A"} variant="subtitle1" fontWeight="bold" /> */}
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "grid--header",

      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={params.row?.status ? params.row?.status : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      headerClassName: "grid--header",

      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={
                params.row?.payment_status ? params.row?.payment_status : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "is_viewed",
      headerName: "Is Viewed",
      headerClassName: "grid--header",

      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              color={params.row?.is_viewed ? "#27ae60" : "#e74c3c"}
              text={`${params.row?.is_viewed ? "Yes" : "No"}`}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "created Date",
      headerName: "Created",
      headerClassName: "grid--header",

      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={
                params.row?.created ? formatDate(params.row?.created) : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "created Time",
      headerName: "Created",
      headerClassName: "grid--header",

      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={
                params.row?.created ? formatHours(params.row?.created) : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      minWidth: 280,
      headerClassName: "grid--header",
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnButton
              onClick={() => handelShow(params.row)}
              type={"show"}
            />
            {params.row.payment_method === "cash" &&
            params.row.status !== "canceled" &&
            params.row.payment_status !== "paid" ? (
              <ColumnButton
                onClick={() => handelPaid(params.row)}
                type={"paid"}
              />
            ) : (
              ""
            )}
            {params.row.status !== "canceled" &&
            params.row.payment_status === "paid" &&
            params.row.status !== "approved" &&
            params.row.status !== "returned" &&
            params.row.status !== "rejected" ? (
              <ColumnButton
                onClick={() => handelApproved(params.row)}
                type={"approve"}
              />
            ) : (
              ""
            )}
            {params.row.status !== "canceled" &&
            params.row.status !== "approved" &&
            params.row.status !== "rejected" &&
            params.row.status !== "returned" &&
            params.row.payment_status === "paid" ? (
              <ColumnButton
                onClick={() => handelRejected(params.row)}
                type={"reject"}
              />
            ) : (
              ""
            )}
            {params.row.status === "approved" &&
            params.row.payment_status === "paid" ? (
              <ColumnButton
                onClick={() => handelReturn(params.row)}
                type={"return"}
              />
            ) : (
              ""
            )}
            <ColumnButton
              onClick={() => handelDelete(params.row)}
              type={"delete"}
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
      data: [],
    }));
    GetOrderData(
      data.filters.skip,
      data.filters.take,
      data.filters.search,
      data.filters.order_number,
      selectedStatus,
      selectedPaymentMethod,
      paymentStatus,
      selectedCreatedFromDate,
      selectedCreatedToDate,
      isView
    )
      .then((res) => {
        console.log("Dtest", res);
        setData((old) => ({
          ...old,
          data: res.results,
          total: res?.count,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, data: [], loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
  }, [filters, updateData]);

  return (
    <>
      <Box m={2}>
        <HeaderGridTabel
          title="Orders"
          icon={
            <HomeWorkIcon
              size={25}
              className="color-primary"
              color={"#08001e"}
            />
          }
          fetchData={fetchData}
          isFilter={true}
          data={data?.data}
          exel={true}
        />
        <Box mt={2}>
          <DataGridTabel columns={columns} data={data} setData={setData} />
        </Box>
        {/* Modal */}
        <ModalShow />
        <ModalInfo />
        <ModalDelete />
        {/* Fillter */}
        <Filters
          emailSearch={emailSearch}
          setEmailSearch={setEmailSearch}
          phoneSearch={phoneSearch}
          setPhoneSearch={setPhoneSearch}
          fetchData={fetchData}
        />
      </Box>
    </>
  );
};

export default Tabel;
