import { deleteData, getData, postData, putData } from "..";

// get Support
export const GetSupportData = async (skip, take, search) => {
    if (skip !== undefined && take !== undefined || search !== search) {
        try {
            const request = await getData(`/support/?page=${skip === 0 ? 1 : skip || take}&page_size=${take}&search=${search}`); // Handle the data
            return request;
        } catch (error) {
            console.error(error); // Handle the error
            throw error;
        }
    }
};

// get Support By Id
export const GetSupportByIdData = async (id) => {
    if (id) {
        try {
            const request = await getData(`/admin/Support/${id}/`); // Handle the data
            return request;
        } catch (error) {
            console.error(error); // Handle the error
            throw error;
        }
    }
};

// post Support
export const PostSupport = async (data) => {
    try {
        const request = await postData(`/support/`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

// put Support
export const PutSupport = async (id, data) => {
    try {
        const request = await putData(`/support/${id}/`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

// delete Support
export const DeleteSupport = async (id) => {
    try {
        const request = await deleteData(`/support/${id}`); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

