import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { openModalInfo } from "../../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../../recoil/global";
import {
  dataSelectCityState,
  dataSelectProvinceState,
  selectedJobFieldState,
} from "../../../recoil/select";
import GeneralModal from "../../GeneralModal/GeneralModal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "leaflet/dist/leaflet.css";
import { posisionMapState } from "../../../recoil/location";
import { PostFiles, PutFiles } from "../../../services/api/files";

export default function ModalInfo() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useRecoilState(openModalInfo);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [sendData, setSendData] = useState(false);

  const dataItem = useRecoilValue(dataClickItem);
  const methodRequest = useRecoilValue(methodRequestStatus);

  const setUpdateData = useSetRecoilState(updateDataState);

  const imgExt = ["png", "jpg", "jpeg", "gif", "svg"];

  // set file data
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    console.log(file);
  };

  // send request
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoading(true)
    // function api request
    if (methodRequest === "add") {
      // data form
      const formData = new FormData();
      formData.append("file", selectedFile);
      // function api request
      PostFiles(formData)
        .then((res) => {
          console.log(res);
          toast.success("Added successfully");
          handleClose();
          setUpdateData(Math.random());
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          if (err.response) {
            const errorData = err.response.data;

            if (typeof errorData === "object" && !Array.isArray(errorData)) {
              // If error data is an object, iterate over its keys
              Object.keys(errorData).forEach((key) => {
                const messages = errorData[key];
                if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                    toast.error(key + ": " + message);
                  });
                } else {
                  toast.error(key + ": " + messages);
                }
              });
            } else if (typeof errorData.message === "string") {
              // If there's a 'message' field, display it directly
              toast.error(errorData.message);
            } else {
              // Handle other types of error data as needed
              toast.error("Unexpected error occurred");
            }
          } else {
            // If there's no response from the server
            toast.error("Check your internet connection");
          }
        });
    } else {
      const data = new FormData(event.currentTarget);
      // data form

      const formData = new FormData();
      formData.append("file", selectedFile);
      const idItem = dataItem?.id;
      PutFiles(idItem, formData)
        .then((res) => {
          console.log(res);
          toast.success("Edit successfully");
          handleClose();
          setUpdateData(Math.random());
          setSendData(true);
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          if (err.response) {
            const errorData = err.response.data;

            if (typeof errorData === "object" && !Array.isArray(errorData)) {
              // If error data is an object, iterate over its keys
              Object.keys(errorData).forEach((key) => {
                const messages = errorData[key];
                if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                    toast.error(key + ": " + message);
                  });
                } else {
                  toast.error(key + ": " + messages);
                }
              });
            } else if (typeof errorData.message === "string") {
              // If there's a 'message' field, display it directly
              toast.error(errorData.message);
            } else {
              // Handle other types of error data as needed
              toast.error("Unexpected error occurred");
            }
          } else {
            // If there's no response from the server
            toast.error("Check your internet connection");
          }
        });
      formData.append("file", selectedFile);
    }
  };

  // close modal
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open === false) {
      setSelectedFile(null);
    }
  }, [open]);

  // hide and show password
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  return (
    <GeneralModal maxWidth="md" open={open} loading={loading} onSubmit={handleSubmit}>
      <Box sx={{ mx: 2, my: 2 }}>
        <Typography sx={{ mx: 2 }} variant="h5" fontSize={18} fontWeight={500}>
          File Information
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%" }} noValidate>
                {imgExt.includes(
                  selectedFile
                    ? selectedFile?.name.split(".").pop()
                    : dataItem?.file?.split(".")?.pop()
                ) ? (
                  <img
                    src={
                      selectedFile
                        ? URL.createObjectURL(selectedFile)
                        : dataItem?.file
                    }
                    alt="Image"
                    style={{ width: "100%", height: "auto", margin: "auto" }}
                  />
                ) : (
                  <Typography
                    sx={{ mx: 2 }}
                    variant="h5"
                    fontSize={18}
                    fontWeight={500}
                  >
                    {selectedFile
                      ? "Not Image " + selectedFile?.name.split(".").pop()
                      : ""}
                  </Typography>
                )}
                {selectedFile && (
                  <Typography
                    sx={{ mx: 2 }}
                    variant="h5"
                    fontSize={18}
                    fontWeight={500}
                  >
                    {selectedFile?.name}
                  </Typography>
                )}
                <Button
                  margin="normal"
                  component="label"
                  variant="outlined"
                  sx={{ width: "100%", mt: 2 }}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </GeneralModal>
  );
}
