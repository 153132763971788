import React, { useEffect, useState } from "react";
import {
  FormControl,
  TextField,
  Autocomplete,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { dataClickItem } from "../../recoil/global";
import { dataSelectGenderState } from "../../recoil/select";

const SelectGender = (props) => {
  const [dataSelectGender, setDataSelectGender] = useRecoilState(
    dataSelectGenderState
  );
  const dataItem = useRecoilState(dataClickItem);
  const [data, setData] = useState([]);

  const handleChange = (event) => {
    setDataSelectGender(event.target.value);
  };

  const genderTransion = {
    male: "Male",
    female: "Female",
    other: "Not-specified",
  };

  useEffect(() => {
    // get data cities
    setData(["Male", "Female", "Not-specified"]);
  }, []);

  return (
    <FormControl fullWidth margin="normal" sx={{ minWidth: 300 }}>
      <InputLabel id="status-label" sx={{ background: "#fff", top: -6 }}>
        Select Gender
      </InputLabel>
      <Select
        size="small"
        sx={{ minWidth: 300 }}
        labelId="status-label"
        id="status-select"
        onChange={handleChange}
        value={dataSelectGender}
      >
        {data?.map((status, index) => (
          <MenuItem key={index} value={status}>
            {status}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectGender;
