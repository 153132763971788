import React, { useEffect,useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useRecoilState } from "recoil";
import { selectedKeyValidityState } from "../../recoil/select";
import { GetKeyValidityData } from "../../services/api/keyValidity";

const SelectKeyValidity = (props) => {
  const [selectedKeyUsersCount, setSelectedKeyUsersCount] = useRecoilState(
    selectedKeyValidityState
  );
  const [data, setData] = useState({
    data: [],
    total: 0,
    loading: false,
  });

  const handleChange = (event) => {
    setSelectedKeyUsersCount(event.target.value);
  };

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
      data: [],
    }));
    GetKeyValidityData(0, 10000, "")
      .then((res) => {
        console.log("Dtest", res);
        setData((old) => ({
          ...old,
          data: res.results,
          total: res?.count,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, loading: false });
      });
  };


  useEffect(() => {
    fetchData();
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <FormControl fullWidth margin="normal" sx={{ minWidth: 300 }}>
      <InputLabel id="status-label" sx={{ background: "#fff", top: -6 }}>
        Select Key Validity
      </InputLabel>
      <Select
        size="small"
        sx={{ minWidth: 300 }}
        labelId="status-label"
        id="status-select"
        onChange={handleChange}
        value={selectedKeyUsersCount}
      >
        {data?.data.map((status, index) => (
          <MenuItem key={index} value={status.validity}>
            {status.validity} {capitalizeFirstLetter(status.validity_unit)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectKeyValidity;
