import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  LinearProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useSetRecoilState } from "recoil";
import { updateDataState } from "../../recoil/global";
import { NoData } from "../Others/NoData";

export const DataGridTabel = (props) => {
  const setUpdateData = useSetRecoilState(updateDataState);

  const borders_shadow = {
    borderRadius: "12px",
    boxShadow:
      "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
  };

  useEffect(() => {
    console.log('props.data', props.data);
    console.log('props.data.filters', props.data.filters);
  }, [props]);


  return (
    <Card
      sx={{
        margin: 0,
        padding: 0,
        ...borders_shadow,
      }}
      className="card-data-grid"
    >
      <CardContent>
        <DataGrid
          sx={{
            height: props.height ? props.height:"80vh",
            width: "100%",
            margin: 0,
            padding: 0,
            "& .grid--header": {
              backgroundColor: "#08001e",
              color: "#fff",
            },
            "& .MuiDataGrid-footerContainer": {
              display: props?.footerShow ? 'none' : 'block',
            },
            ...borders_shadow,
          }}
          pageSize={props.data.filters.take ? props.data.filters.take : 25}
          rowCount={props.data.total ? props.data.total : 0}
          rows={props.data.data ? props.data.data : []}
          loading={props.data.loading}
          page={props.data.filters.page}
          columns={props.columns}
          rowHeight={65}
          pagination
          paginationMode="server"
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: NoData,
          }}
          onPageSizeChange={(newPageSize) => {
            props.setData((old) => ({
              ...old,
              filters: {
                ...old.filters,
                take: newPageSize,
                skip: 0,
                page: 0,
              },
            }));
            setUpdateData(Math.random());
          }}
          onPageChange={(newPage) => {
            props.setData((old) => ({
              ...old,
              filters: {
                ...old.filters,
                skip: newPage + 1,
                page: newPage,
              },
            }));
            setUpdateData(Math.random());
          }}
        />
      </CardContent>
    </Card>
  );
};
