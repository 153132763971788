import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { openModalInfo } from "../../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../../recoil/global";
import {
  dataSelectCityState,
  dataSelectProvinceState,
  selectedJobFieldState,
  selectedUserState,
  selectedTransactionTypeState,
} from "../../../recoil/select";
import GeneralModal from "../../GeneralModal/GeneralModal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "leaflet/dist/leaflet.css";
import { posisionMapState } from "../../../recoil/location";
import { PostTransactions } from "../../../services/api/transactions";
import MultiImage from "../../Others/MultiImage";
import SelectTransactionType from "../../Selects/SelectTransactionType";
import SelectedUser from "../../Selects/SelectUser";


export default function ModalInfo() {
  const [open, setOpen] = useRecoilState(openModalInfo);
  const [showPassword, setShowPassword] = useState(false);

  const [selectedUser, setSelectedUser] = useRecoilState(selectedUserState);
  const [selectedTransactionType, setSelectedTransactionType] = useRecoilState(
    selectedTransactionTypeState
  );

  const [loading, setLoading] = useState(false);

  const dataItem = useRecoilValue(dataClickItem);
  const methodRequest = useRecoilValue(methodRequestStatus);

  const setUpdateData = useSetRecoilState(updateDataState);

  // send request
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    setLoading(true)
    // function api request
    if (methodRequest === "add" && selectedUser && selectedTransactionType) {
      // data form
      const formData = new FormData();

      const infoData = {
        transaction_type: selectedTransactionType,
        user: selectedUser,
        amount: data.get("amount"),
        description: data.get("description"),
      };

      // function api request
      PostTransactions(infoData)
        .then((res) => {
          console.log(res);
          toast.success("Added successfully");
          handleClose();
          setUpdateData(Math.random());
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          if (err.response) {
            const errorData = err.response.data;

            if (typeof errorData === "object" && !Array.isArray(errorData)) {
              // If error data is an object, iterate over its keys
              Object.keys(errorData).forEach((key) => {
                const messages = errorData[key];
                if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                    toast.error(key + ": " + message);
                  });
                } else {
                  toast.error(key + ": " + messages);
                }
              });
            } else if (typeof errorData.message === "string") {
              // If there's a 'message' field, display it directly
              toast.error(errorData.message);
            } else {
              // Handle other types of error data as needed
              toast.error("Unexpected error occurred");
            }
          } else {
            // If there's no response from the server
            toast.error("Check your internet connection");
          }
          ;
        });
    }
  };

  // close modal
  const handleClose = () => {
    setOpen(false);
  };

  // hide and show password
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  return (
    <GeneralModal maxWidth="md" open={open} loading={loading} onSubmit={handleSubmit}>
      <Box sx={{ mx: 2, my: 2 }}>
        <Typography sx={{ mx: 2 }} variant="h5" fontSize={18} fontWeight={500}>
          Transactions Information
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box noValidate>
                <TextField
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  id="description"
                  label="description"
                  name="description"
                  autoComplete="description"
                  autoFocus
                />
                <SelectedUser />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
                noValidate
              >
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  id="amount"
                  label="Amount"
                  name="amount"
                  autoComplete="amount"
                  autoFocus
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  inputMode="numeric"
                  required
                />
                <SelectTransactionType />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </GeneralModal>
  );
}
