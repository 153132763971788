import React from "react";
import Slider from "../components/Slidbar/Slidbar";
import Tabel from "../components/KeyValidity/Tabel"

export const KeyValidity = () => {
  return (
    <>
      <Slider />
      <Tabel />
    </>
  );
};
