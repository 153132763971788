import { deleteData, getData, postData, putData } from "..";

// get Files
export const GetFilesData = async (skip, take, search) => {
  if ((skip !== undefined && take !== undefined) || search !== search) {
    try {
      const request = await getData(
        `/files?page=${
          skip === 0 ? 1 : skip || take
        }&page_size=${take}&search=${search}`
      ); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// get Files By Id
export const GetFilesByIdData = async (id) => {
  if (id) {
    try {
      const request = await getData(`/admin/Files/${id}`); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// post Files
export const PostFiles = async (data) => {
  try {
    const request = await postData(`/files/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// put Files
export const PutFiles = async (id, data) => {
  try {
    const request = await putData(`/files/${id}/`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// delete Files
export const DeleteFiles = async (id) => {
  try {
    const request = await deleteData(`/files/${id}/`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};
