import * as React from "react";
import { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import img_logo from "../../assets/images/logo.png";
import { InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { userInfo } from "../../recoil";
import { openDropState, openSlidbarState } from "../../recoil/global";
import WorkIcon from "@mui/icons-material/Work";
import TitleIcon from "@mui/icons-material/Title";
import { SlidbarItem } from "./SlidbarItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BadgeIcon from "@mui/icons-material/Badge";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import LogoutIcon from "@mui/icons-material/Logout";
import { MenuProfile } from "../Auth/MenuProfile";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import MapIcon from "@mui/icons-material/Map";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CategoryIcon from "@mui/icons-material/Category";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import BusinessIcon from "@mui/icons-material/Business";
import InventoryIcon from "@mui/icons-material/Inventory";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SliderIcon from "@mui/icons-material/Slideshow";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import KeyIcon from "@mui/icons-material/Key";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import UersIcon from "@mui/icons-material/Group";
import { GetNotificationsData } from "../../services/api/notifications";
import { notificationsState } from "../../recoil";
import { updateDataState } from "../../recoil/global";
import { dataTabelState } from "../../recoil/Tabel";
import {
  selectedCategoryState,
  selectedCityState,
  selectedCompanyState,
  selectedCreatedFromDateState,
  selectedCreatedToDateState,
  selectedStatusState,
  selectedSubCategoryState,
  selectedTagsState,
  selectedUserState,
  selectedCurencyState,
  selectedExpiredState,
  selectedIsViewState,
  selectedKeyUserCountState,
  selectedKeyValidityState,
  selectedKeyValidityUnitState,
  selectedNotificationLevelState,
  selectedPaymentMethodState,
  selectedPaymentStatus,
  selectedTransactionTypeState,
  selectedWholeSaleState,
} from "../../recoil/select";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "js-cookie";
import { icon } from "leaflet";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Slider = () => {
  const [notificationsData, setNotificationsData] =
    useRecoilState(notificationsState);


  const theme = useTheme();
  const [open, setOpen] = useRecoilState(openSlidbarState);
  const [openDrop, setOpenDrip] = useRecoilState(openDropState);
  const setUpdateData = useSetRecoilState(updateDataState);
  const setCategory = useSetRecoilState(selectedCategoryState);
  const setSubCategory = useSetRecoilState(selectedSubCategoryState);
  const setCompany = useSetRecoilState(selectedCompanyState);
  const setCity = useSetRecoilState(selectedCityState);
  const setUser = useSetRecoilState(selectedUserState);
  const setTags = useSetRecoilState(selectedTagsState);
  const setCreatedFromDate = useSetRecoilState(selectedCreatedFromDateState);
  const setCreatedToDate = useSetRecoilState(selectedCreatedToDateState);
  const setPaymentMethod = useSetRecoilState(selectedPaymentMethodState);
  const setPaymentStatus = useSetRecoilState(selectedPaymentStatus);
  const setTransactionType = useSetRecoilState(selectedTransactionTypeState);
  const setWholeSale = useSetRecoilState(selectedWholeSaleState);
  const setKeyUserCount = useSetRecoilState(selectedKeyUserCountState);
  const setKeyValidity = useSetRecoilState(selectedKeyValidityState);
  const setKeyValidityUnit = useSetRecoilState(selectedKeyValidityUnitState);
  const setExpired = useSetRecoilState(selectedExpiredState);
  const setIsView = useSetRecoilState(selectedIsViewState);
  const setNotificationLevel = useSetRecoilState(
    selectedNotificationLevelState
  );
  const setCurrency = useSetRecoilState(selectedCurencyState);
  const setSelectedStatus = useSetRecoilState(selectedStatusState);
  const setData = useSetRecoilState(dataTabelState);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(3);

  useEffect(() => {
    setCategory("");
    setSubCategory("");
    setCompany("");
    setCity("");
    setUser("");
    setTags("");
    setCreatedFromDate("");
    setCreatedToDate("");
    setPaymentMethod("");
    setPaymentStatus("");
    setTransactionType("");
    setWholeSale("");
    setKeyUserCount("");
    setKeyValidity("");
    setKeyValidityUnit("");
    setExpired("");
    setIsView("");
    setNotificationLevel("");
    setCurrency("");
    setSelectedStatus("");
    setData({
      data: [],
      loading: false,
      dialog: false,
      filters: {
        skip: 0,
        take: 25,
        page: 0,
        search: "",
      },
    });
  }, []);

  const [userInfoData,setUserInfoData] = useRecoilState(userInfo);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(0);
    const level = ["low", "normal", "important"];
    const getNotifications = async () => {
      level.map((item) => {
        GetNotificationsData(0, 10000, "", item)
          .then((res) => {
            setNotificationsData((old) => ({
              ...old,
              [item]: res.results.length,
            }));
            setIsLoading((old) => old + 1);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    };
    !notificationsData.low ||
    !notificationsData.normal ||
    !notificationsData.important
      ? getNotifications()
      : setNotificationsData(notificationsData);
  }, []);

  useEffect(() => {
    console.log("isLoading", isLoading);
  }, [isLoading]);

  const dataSlidbar = [
    {
      icon: <HomeIcon className="menu-icon" />,
      text: "Home",
      path: "/",
    },
    {
      icon: <NotificationsActiveIcon className="menu-icon" />,
      text: "Notifications",
      path: "/notifications",
    },
    {
      icon: <ShoppingCartIcon className="menu-icon" />,
      text: "Order",
      path: "/order",
    },
    {
      icon: <SubdirectoryArrowRightIcon className="menu-icon" />,
      text: "Category",
      path: "/category",
    },
    {
      icon: <SubdirectoryArrowRightIcon className="menu-icon" />,
      text: "Sub Category",
      path: "/sub_category",
    },
    {
      icon: <BusinessIcon className="menu-icon" />,
      text: "Company",
      path: "/company",
    },
    {
      icon: <InventoryIcon className="menu-icon" />,
      text: "Product",
      path: "/product",
    },
    {
      icon: <KeyIcon className="menu-icon" />,
      text: "Key Users Count",
      path: "/keyUsersCount",
    },
    {
      icon: <AccessTimeIcon className="menu-icon" />,
      text: "Key Validity",
      path: "/keyValidity",
    },
    {
      icon: <SupportAgentIcon className="menu-icon" />,
      text: "Support",
      path: "/support",
    },
    {
      icon: <FileCopyIcon className="menu-icon" />,
      text: "Files",
      path: "/files",
    },
    {
      icon: <SliderIcon className="menu-icon" />,
      text: "Home Slider",
      path: "/homeSlider",
    },
    {
      icon: <AttachMoneyIcon className="menu-icon" />,
      text: "Transactions",
      path: "/transactions",
    },
    {
      icon: <UersIcon className="menu-icon" />,
      text: "Users",
      path: "/users",
    },
    {
      icon: <HomeWorkIcon className="menu-icon" />,
      text: "WholeSale",
      path: "/wholesale",
    },
  ];

  return (
    <Box sx={{ display: "flex", position: "relative" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ background: "#ecf0f1", display: userInfoData ? "flex" : "none" }}
      >
        <Toolbar sx={{ display: "flex" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            sx={{
              position: "absolute",
              left: 26,
              marginRight: 5,
              borderRadius: "50%",
              background: "#08001e",
              "&:hover": {
                background: "#08001e",
              },
            }}
            hover
          >
            {open ? (
              theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )
            ) : (
              <MenuIcon />
            )}
          </IconButton>
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              left: 60,
            }}
          >
            <img
              style={{ width: 70, marginLeft: 5, marginRight: 5 }}
              src={img_logo}
              alt="logo"
            />
          </Box>
          <Box
            sx={{
              display: "none",
              position: "absolute",
              right: "calc(50vw - 250px)",
            }}
          >
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 500,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              position: "absolute",
              right: 60,
            }}
          >
            {notificationsData.low ? (
              <Box
                sx={{
                  height: 40,
                  width: 40,
                  borderRadius: "999999px",
                  background: "#1ae83c",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography textAlign={"center"}>
                  {notificationsData.low}
                </Typography>
              </Box>
            ) : (
              <CircularProgress color="success" />
            )}
            {notificationsData.normal ? (
              <Box
                sx={{
                  height: 40,
                  width: 40,
                  borderRadius: "999999px",
                  background: "#dee81a",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography textAlign={"center"}>
                  {notificationsData.normal}
                </Typography>
              </Box>
            ) : (
              <CircularProgress color="secondary" />
            )}

            {notificationsData.important ? (
              <Box
                sx={{
                  height: 40,
                  width: 40,
                  borderRadius: "999999px",
                  background: "#e81a1a",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography textAlign={"center"}>
                  {notificationsData.important}
                </Typography>
              </Box>
            ) : (
              <CircularProgress color="primary" />
            )}
          </Box>

          <Box
            sx={{
              position: "absolute",
              right: 5,
            }}
            hover
          >
            <MenuProfile />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{ display: userInfoData ? "flex" : "none" }}
      >
        <Divider />
        <List sx={{ height: "100%" }}>
          <ListItem
            disablePadding
            sx={{ display: "block", border: "none", mt: 8 }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    padding: 1,
                    borderRadius: "999999px",
                    background: "#b2bec3",
                    width: 40,
                    height: 40,
                  }}
                >
                  <AccountCircleIcon className="menu-icon-user" />
                </Box>
              </ListItemIcon>
              <Box sx={{ width: open ? "100%" : "0px", overflow: "hidden" }}>
                <ListItemText
                  primary={<>{userInfoData.email.split(" ")[0]} ...</>}
                />
                <Typography variant="caption" display="block" gutterBottom>
                  {userInfoData.email.split("@")[0]} ...
                </Typography>
              </Box>
            </ListItemButton>
          </ListItem>

          {dataSlidbar.map((item, index) => {
            return (
              <SlidbarItem
                key={index}
                index={index}
                open={open}
                openDrop={openDrop}
                setOpenDrip={setOpenDrip}
                text={item.text}
                path={item.path}
                child={item.child}
                icon={item.icon}
              />
            );
          })}
          <ListItem
            disablePadding
            sx={{
              display: "block",
              width: "calc(100% - 1rem)",
              m: "0.5rem",
              border: "1px solid #d4d4d4",
              borderRadius: "10px",
              backgroundColor: "#fff",
              overflow: "hidden",
              boxShadow: "1px 4px 10px rgba(0,0,0,.1)",
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                setData((old) => ({
                  ...old,
                  filters: {
                    ...old.filters,
                    search: "",
                  },
                }));
                Cookies.remove("token")
                setUserInfoData(null)
                setTimeout(() => {
                  navigate("/login");
                }, 500);
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LogoutIcon className="log-out" />
              </ListItemIcon>
              <ListItemText
                primary={"Log Out"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            sx={{
              height: 10,
            }}
          ></ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

export default Slider;
