import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";

const MultiImage = ({ imgList, setImgList, imageKey, path }) => {
  const handleDeleteImage = (id) => {
    const newList = imgList.map((item) => {
      if (item.id === id && item?.state === "new") {
        return { ...item, state: "null" };
      } else if (item.id === id) {
        return { ...item, state: "deleted" };
      }
      return item;
    });
    setImgList(newList);
  };

  const handleImageChange = (event, id) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      const newList = imgList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [imageKey]: URL.createObjectURL(file),
            file: file,
            base64: base64String,
            state: item?.state === "new" ? "new" : "updated",
          };
        }
        return item;
      });
      setImgList(newList);
    };

    reader.readAsDataURL(file);
  };

  const handleAddImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      const newList = imgList.concat({
        id: uuidv4(),
        [imageKey]: URL.createObjectURL(file),
        file: file,
        base64: base64String,
        state: "new",
      });
      setImgList(newList);
    };

    reader.readAsDataURL(file);
  };

  return (
    <Box
      sx={{
        mt: 3,
      }}
    >
      <Grid
        container
        sx={{
          mx: 2,
        }}
        spacing={2}
      >
        {imgList
          .filter((item) => item?.state !== "deleted" && item?.state !== "null")
          .map((item, index) => (
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
              sm={6}
              key={item.id}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                  width: "100%",
                  mt: 3,
                }}
                noValidate
              >
                <Box
                  sx={{
                    width: "auto",
                  }}
                >
                  <Button
                    margin="normal"
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    sx={{
                      width: "100%",

                      borderRadius: "5px 5px 0 0",
                    }}
                  >
                    Change file
                    <input
                      type="file"
                      name={`file-${item.id}`}
                      onChange={(e) => handleImageChange(e, item.id)}
                      style={{ display: "none" }}
                    />
                  </Button>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                      width: "100%",
                      backgroundColor: "white",
                    }}
                  >
                    <img
                      src={imageKey ? item[imageKey] : item?.image_file}
                      alt="product"
                      style={{
                        width: "300px",
                        height: "auto",
                        margin: "auto",
                      }}
                    />
                  </Box>
                  <Button
                    sx={{
                      width: "100%",
                      backgroundColor: "red",
                      color: "white",
                      borderRadius: "0 0 5px 5px",
                    }}
                    variant="contained"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDeleteImage(item.id)}
                  >
                    Remove
                  </Button>
                </Box>
              </Box>
              {path && (
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  required
                  id={`path-${item.id}`}
                  label="Path"
                  name="path"
                  autoComplete="path"
                  sx={{
                    width: "80%",
                  }}
                  autoFocus
                  defaultValue={item[path]}
                  onChange={(e) => {
                    const newList = imgList.map((oitem, i) => {
                      if (oitem.id === item.id) {
                        return { ...oitem, [path]: e.target.value };
                      }
                      return oitem;
                    });
                    setImgList(newList);
                  }}
                />
              )}
            </Grid>
          ))}
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          mt: 3,
        }}
        noValidate
      >
        <Button
          margin="normal"
          component="label"
          variant="outlined"
          startIcon={<CloudUploadIcon />}
          sx={{
            width: "90%",
            my: 2,
          }}
        >
          Upload file
          <input
            type="file"
            name={`UpFile`}
            onChange={handleAddImage}
            style={{ display: "none" }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default MultiImage;
