import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useRecoilState } from 'recoil';
import { selectedIsViewState } from '../../recoil/select';

const SelectisView = (props) => {
    const [isView, setIsView] = useRecoilState(selectedIsViewState);

    const handleChange = (event) => {
        setIsView(event.target.value);
    };

    const data = ['', 'true', 'false']

    return (
        <FormControl fullWidth margin="normal" sx={{ minWidth: 300 }}>
            <InputLabel id="isView-label" sx={{ background: '#fff' }}>
                Select Is Viewed
            </InputLabel>
            <Select
                size='small'
                sx={{ minWidth: 300 }}
                labelId="isView-label"
                id="isView-select"
                onChange={handleChange}
                value={isView}
            >
                {data?.map((isView, index) => (
                    <MenuItem key={index} value={isView}>
                        {isView}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectisView;
