import { Typography } from '@mui/material'
import React from 'react'

export const ColumnText = (props) => {
  return (
    <Typography variant={props.variant} fontWeight={props.fontWeight} color={props.color}>
      {props.text}
    </Typography>
  )
}
