import React from "react";
import { TextField, Typography, Box } from "@mui/material";

const JsonViewer = ({ data, prefix = "" }) => {
  // Function to check if a value is an object
  const isObject = (value) => {
    return value && typeof value === "object" && !Array.isArray(value);
  };

  const transformStatus = (status) => {
    return status
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <Box>
      {Object.keys(data).map((key) => {
        const value = data[key];
        const fullKey = prefix ? `${prefix}.${key}` : key;

        if (isObject(value)) {
          // If the value is an object, recursively render the JsonViewer
          return (
            <Box key={fullKey} sx={{ marginLeft: 2 }}>
              <Typography variant="h6">{transformStatus(key)}</Typography>
              <JsonViewer data={value} prefix={fullKey} />
            </Box>
          );
        } else if (Array.isArray(value)) {
          // If the value is an array, handle each item
          return (
            value.lenght > 0 && (
              <Box key={fullKey} sx={{ marginLeft: 2 }}>
                <Typography variant="h6">{transformStatus(key)}</Typography>
                {value.map((item, index) => (
                  <JsonViewer
                    key={`${fullKey}[${index}]`}
                    data={item}
                    prefix={`${fullKey}[${index}]`}
                  />
                ))}
              </Box>
            )
          );
        } else {
          // If the value is a primitive, render a TextField
          return value ? (
            <TextField
              key={fullKey}
              label={transformStatus(key)}
              value={value || "N/A"}
              fullWidth
              sx={{
                width: "45%",
                mx: "2.5%",
              }}
              size="small"
              margin="normal"
              InputProps={{ readOnly: true }}
            />
          ) : (
            <></>
          );
        }
      })}
    </Box>
  );
};

export default JsonViewer;
