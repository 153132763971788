import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  Grid,
  TextField,
  Box,
} from "@mui/material";
import { IoIosAddCircleOutline } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";

import { useRecoilState, useSetRecoilState } from "recoil";
import { dataTabelState } from "../../recoil/Tabel";
import { openModalInfo } from "../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
} from "../../recoil/global";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ExportButton from "../Others/ExportButton";

export const HeaderGridTabel = (props) => {
  const [dataState, setDataState] = useRecoilState(dataTabelState);
  const setOpenAdd = useSetRecoilState(openModalInfo);
  const setDataItem = useSetRecoilState(dataClickItem);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);
  const setOpenFilter = useSetRecoilState(openFilterState);

  // function add
  const handleAdd = () => {
    setOpenAdd(true);
    setDataItem(null);
    setMethodRequest("add");
  };

  const borders_shadow = {
    borderRadius: "12px",
    boxShadow:
      "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
  };
  return (
    <Card
      elevation={0}
      sx={{
        ...borders_shadow,
      }}
    >
      <CardHeader
        avatar={props.icon}
        title={
          <Typography variant="h6" fontWeight="bold" color={"#08001e"}>
            {props.title}
          </Typography>
        }
        action={
          <>
            {props.exel && <ExportButton data={props.data} fileName="data" />}
            {props.btn && (
              <Button
                variant="contained"
                startIcon={<IoIosAddCircleOutline size={25} />}
                sx={{
                  backgroundColor: "#08001e",
                  "&:hover": {
                    backgroundColor: "#08001e",
                  },
                }}
                onClick={() => handleAdd()}
              >
                {props.btn}
              </Button>
            )}
          </>
        }
      />
      <CardContent>
        <Grid container columnSpacing={1} rowSpacing={2} sx={{ px: 1 }}>
          <Grid item xs={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {!props.noSearch && (
                <TextField
                  value={dataState.filters.search}
                  sx={{
                    borderColor: "#fff",
                    borderRadius: "12px",
                  }}
                  onChange={(e) => {
                    setDataState((old) => ({
                      ...old,
                      filters: {
                        ...old.filters,
                        search: e.target.value,
                      },
                    }));
                  }}
                  size="small"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      props.fetchData();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          props.fetchData();
                        }}
                      >
                        <AiOutlineSearch />
                      </IconButton>
                    ),
                  }}
                  placeholder="Search"
                  label="Search"
                  fullWidth
                />
              )}
              {props.isFilter && (
                <IconButton
                  onClick={() => setOpenFilter({ right: true })}
                  aria-label="delete"
                  size="large"
                >
                  <FilterAltIcon fontSize="inherit" />
                </IconButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
