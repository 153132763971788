import { deleteData, getData, postData, putData,patchData } from "..";

// get Company
export const GetCompanyData = async (skip, take, search) => {
    if (skip !== undefined && take !== undefined || search !== search) {
        try {
            const request = await getData(`/company/?page=${skip === 0 ? 1 : skip || take}&page_size=${take}&search=${search}`); // Handle the data
            return request;
        } catch (error) {
            console.error(error); // Handle the error
            throw error;
        }
    }
};

// get Company By Id
export const GetCompanyByIdData = async (id) => {
    if (id) {
        try {
            const request = await getData(`/company/${id}/`); // Handle the data
            return request;
        } catch (error) {
            console.error(error); // Handle the error
            throw error;
        }
    }
};

// post Company
export const PostCompany = async (data) => {
    try {
        const request = await postData(`/company/`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

// patch Company
export const PatchCompany = async (id, data) => {
    try {
        const request = await patchData(`/company/${id}/`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

// put Company
export const PutCompany = async (id, data) => {
    try {
        const request = await putData(`/company/${id}/`, data); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};

// delete Company
export const DeleteCompany = async (id) => {
    try {
        const request = await deleteData(`/company/${id}/`); // Handle the data
        return request;
    } catch (error) {
        console.error(error); // Handle the error
        throw error;
    }
};


