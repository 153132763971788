import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { openModalInfo } from "../../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../../recoil/global";
import GeneralModal from "../../GeneralModal/GeneralModal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "leaflet/dist/leaflet.css";
import { PostCategory, PutCategory } from "../../../services/api/category";
import {
  PatchOrderApproved,
  PatchOrderPaid,
  PatchOrderRejected,
  PatchOrderReturn,
} from "../../../services/api/order";

export default function ModalInfo() {
  const [open, setOpen] = useRecoilState(openModalInfo);
  const [selectedFile, setSelectedFile] = useState(null);
  const [sendData, setSendData] = useState(false);
  const [loading, setLoading] = useState(false);
  const dataItem = useRecoilValue(dataClickItem);
  const methodRequest = useRecoilValue(methodRequestStatus);

  const setUpdateData = useSetRecoilState(updateDataState);

  // set file data
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    console.log(file);
  };

  // send request
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoading(true)
    // function api request 
    if (methodRequest === "paid") {
      // data form
      // function api request
      PatchOrderPaid(dataItem.id)
        .then((res) => {
          console.log(res);
          toast.success("Make as Paid");
          handleClose();
          setUpdateData(Math.random());
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          if (err.response) {
            const errorData = err.response.data;

            if (typeof errorData === "object" && !Array.isArray(errorData)) {
              // If error data is an object, iterate over its keys
              Object.keys(errorData).forEach((key) => {
                const messages = errorData[key];
                if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                    toast.error(key + ": " + message);
                  });
                } else {
                  toast.error(key + ": " + messages);
                }
              });
            } else if (typeof errorData.message === "string") {
              // If there's a 'message' field, display it directly
              toast.error(errorData.message);
            } else {
              // Handle other types of error data as needed
              toast.error("Unexpected error occurred");
            }
          } else {
            // If there's no response from the server
            toast.error("Check your internet connection");
          }
        });
    }
    if (methodRequest === "approved") {
      // data form
      // function api request
      let dataApproved = {
        approved_notes: data.get("note") ? data.get("note") : "No note",
      };
      PatchOrderApproved(dataItem.id, dataApproved)
        .then((res) => {
          console.log(res);
          toast.success("Make as Approved");
          handleClose();
          setUpdateData(Math.random());
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          if (err.response) {
            const errorData = err.response.data;

            if (typeof errorData === "object" && !Array.isArray(errorData)) {
              // If error data is an object, iterate over its keys
              Object.keys(errorData).forEach((key) => {
                const messages = errorData[key];
                if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                    toast.error(key + ": " + message);
                  });
                } else {
                  toast.error(key + ": " + messages);
                }
              });
            } else if (typeof errorData.message === "string") {
              // If there's a 'message' field, display it directly
              toast.error(errorData.message);
            } else {
              // Handle other types of error data as needed
              toast.error("Unexpected error occurred");
            }
          } else {
            // If there's no response from the server
            toast.error("Check your internet connection");
          }
        });
    }
    if (methodRequest === "rejected") {
      // data form
      // function api request
      let dataRejected = {
        rejected_notes: data.get("note") ? data.get("note") : "No note",
      };
      PatchOrderRejected(dataItem.id, dataRejected)
        .then((res) => {
          console.log(res);
          toast.success("Make as Rejected");
          handleClose();
          setUpdateData(Math.random());
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          if (err.response) {
            const errorData = err.response.data;

            if (typeof errorData === "object" && !Array.isArray(errorData)) {
              // If error data is an object, iterate over its keys
              Object.keys(errorData).forEach((key) => {
                const messages = errorData[key];
                if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                    toast.error(key + ": " + message);
                  });
                } else {
                  toast.error(key + ": " + messages);
                }
              });
            } else if (typeof errorData.message === "string") {
              // If there's a 'message' field, display it directly
              toast.error(errorData.message);
            } else {
              // Handle other types of error data as needed
              toast.error("Unexpected error occurred");
            }
          } else {
            // If there's no response from the server
            toast.error("Check your internet connection");
          }
        });
    }
    if (methodRequest === "return") {
      // data form
      // function api request
      PatchOrderReturn(dataItem.id)
        .then((res) => {
          console.log(res);
          toast.success("Make as return");
          handleClose();
          setUpdateData(Math.random());
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          toast.error(<div>Error cannot make as return</div>);
        });
    }
  };

  // close modal
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <GeneralModal
      title={"Operation"}
      maxWidth="sm"
      open={open}
      onSubmit={handleSubmit}
      loading={loading}
    >
      <Box sx={{ mx: 2, my: 2 }}>
        <Typography variant="h5" fontSize={18} fontWeight={500}>
          {methodRequest === "paid"
            ? `Do you want to confirm payment for the ${
                " (" + dataItem?.order_number + ") "
              } order?`
            : methodRequest === "approved"
            ? `Do you want to approve the order ${
                " (" + dataItem?.order_number + ") "
              } ?`
            : methodRequest === "rejected"
            ? `Do you want to reject the order ${
                " (" + dataItem?.order_number + ") "
              } ?`
            : methodRequest === "return"
            ? `Do you want to return the order ${
                " (" + dataItem?.order_number + ") "
              } ?`
            : ""}
        </Typography>
        {methodRequest !== "paid" && methodRequest !== "return" && (
          <TextField
            margin="normal"
            fullWidth
            id="note"
            label="Note"
            name="note"
            autoComplete="note"
            autoFocus
          />
        )}
      </Box>
    </GeneralModal>
  );
}
